@use "vars" as *;

.l-page-mv--gallery{
	position: relative;
	img{
		width: 100%;
		height: 100vh;
		object-fit: cover;
	}
	.scroll-dow{
		width: 64px;
		height: 64px;
		border-radius: 50%;
		@include flex(center,center);
		color: white;
		@include font-size(12);
		font-weight: 500;
		background: rgba(#141414,0.7);
		position: absolute;
		@include v-align;
		right: 12.4%;
		&:after{
			content: "";
			border: solid #fff;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(45deg);
			position: absolute;
			bottom: 11px;
		}
	}
}


.sec-gallery-detail{
	padding: 39px 0 157px;
	@include mq(){
		padding: 11px 0 54px;
	}
	.l-inner{
		position: relative;
		max-width: 1036px;
	}
	.c-ttl__03{
		color: #191919;
		border-bottom: none;
		padding: 0;
		letter-spacing: 4px;
	}
	.c-btn__02{
		width: 163px;
		letter-spacing: 2.5px;
	}
}
.sticky{;
	position: sticky;
	top: 0;
	right: 0;
	height: 0;
	@include flex(end);
	z-index: 10;
	@include mq(){
		padding-top: 60px;
	}
}

.gallery-award{
	display: inline-block;
	position: relative;
	font-size: 1.6rem;
	font-weight: 500;
	color: #707070;
	letter-spacing: 1px;
	z-index: 2;
	@include smooth-transition;
	padding: 0 0 23px;
	@include mq(){
		&.fixed{
			position: fixed;
			top: 80px;
			right: 15px;
			&:after{
				top: calc(100% - 12px);
				height: 28px;
			}
		}

	}
	&:hover {
		@include mq(md-min) {
			opacity: .7;
		}
	}
	&:after{
		content: "";
		width: 1px ;
		background: #707070;
		height: 43px;
		position: absolute;
		top: calc(100% + 6px);
		@include h-align;
		@include mq(){
			top: calc(100% + 12px);
			height: 28px;
		}
	}
	&:before{
		content: "";
		display: block;
		width: 3px;
		height: 3px;
		border-radius: 50%;
		border: 1px solid #707070;
		z-index: 1;
		position: absolute;
		top: calc(100% + 6px);
		@include h-align;
		background: white;

		@include mq(md-min){
			animation:
					circlemove 1.6s ease-in-out infinite,
					cirlemovehide 1.6s ease-out infinite;
		}
		@include mq(){
			top: calc(100% + 2px);
			animation:
					circlemovesp 1.6s ease-in-out infinite,
					cirlemovehide 1.6s ease-out infinite;
		}
	}
	&.off{
		&:before{
			display: none;
		}
	}
}

@keyframes circlemove{
	0%{
		top: 95%;
	}
	100%{
		top: calc(100% + 50px);
	}
}
@keyframes circlemovesp{
	0%{
		top: 30px;
	}
	100%{
		top: calc(100% + 37px);
	}
}

@keyframes cirlemovehide{
	0%{opacity:0}
	50%{opacity:1;}
	80%{opacity:0.9;}
	100%{opacity:0;}
}

.l-gallery-article{
	@include fts(16,28);
	font-weight: 300;
	margin-bottom: 160px;
	@include mq(){
		@include fts(14,24);
		margin-bottom: 62px;
	}
	&__category{
		display: block;
		color: #919191;
		margin-bottom: 9px;
		@include mq(){
			margin-bottom: 0;
		}
	}
	&__date{
		display: block;
		font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: 9px;
		@include mq(){
			margin-bottom: 0;
		}
	}
	&__block{
		@include flex(0,0,wrap);
		&-ct{
			width: 39%;
			padding: 0 6% 0 0;
			letter-spacing: 0.9px;
			position: relative;
			top: -3px;
			text-align: justify;
			@include mq(){
				top: auto;
				width: 100%;
				padding: 0;
				margin-bottom: 70px;
			}
		}
		&-img{
			width: 61%;
			@include mq(){
				width: auto;
				margin: 0 -18px;
			}
			img{

			}
			li{
				overflow: hidden;
				a{
					display: block;
					overflow: hidden;
					img{
						width: 100%;
						margin-bottom: 2px;
						-webkit-transition: all 0.2s linear;
						-moz-transition: all 0.2s linear;
						-o-transition: all 0.2s linear;
						-ms-transition: all 0.2s linear;
						transition: all 0.2s linear;
					}
					&:hover{
						opacity: 1;
						-webkit-transform: scale(1.1,1.1);
						-moz-transform: scale(1.1,1.1);
						-o-transform: scale(1.1,1.1);
						-ms-transform: scale(1.1,1.1);
						transform: scale(1.1,1.1);
					}
				}
			}
		}
	}
	h1,h2,h3,h4,h5{
		display: block;
		font-weight: 400;
		margin-bottom: 30px;
	}
	h1{
		@include font-size(32);
		letter-spacing: 4.5px;
		margin-bottom: 156px;
		@include mq(){
			@include font-size(20);
			letter-spacing: 2.8px;
			margin-bottom: 73px;
		}
	}
	h2{
		@include font-size(26);
		@include mq(){
			@include font-size(18);
		}
	}
	h3{
		@include font-size(20);
		@include mq(){
			@include font-size(16);
		}
	}
	h4{
		@include font-size(18);
		@include mq(){
			@include font-size(14);
		}
	}
	p{
		margin-bottom: 29px;
		@include mq(){
			margin-bottom: 23px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__award{
		padding: 36px 0 0;
		font-weight: 300;
		@include mq(){
			padding: 57px 0 0;
		}
		&-ttl{
			display: block;
			@include font-size(32);
			font-weight: 500;
			border-bottom: 1px solid;
			letter-spacing: 3px;
			line-height: 1.7;
			margin-bottom: 30px;
			color: #191919 ;
			@include mq(){
				@include font-size(24);
				border-bottom: 1px solid;
				letter-spacing: 2px;
				line-height: 1.7;
				margin-bottom: 10px;
			}
		}
		table{
			width: 100%;
			tr{
				th,td{

				}
				th{
					font-family: $font-en;
					font-weight: 500;
				}
				td{

				}
			}
		}
	}
}

.p-project-other{
	@include flex(0,0,wrap);
	margin-bottom: 38px;
	@include mq(){
		margin: 0 -18px 42px;
	}
	&__item{
		width: 33.333%;
		position: relative;
		@include mq(){
			width: 100%;

		}
		img{
			width: 100%;
		}
		a{
			display: block;
			color: white;
			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(black,0.53);
				@include smooth-transition;
				opacity: 0;
				visibility: hidden;
				@include mq(){
					display: none;
				}
			}
			&:hover{
				opacity: 1;
				&:before{
					opacity: 1;
					visibility: visible;
				}
				.p-project-other__ttl{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	&__ttl{
		display: block;
		position: absolute;
		@include v-align;
		left: 0;
		right: 0;
		padding: 0 5px;
		text-align: center;
		@include font-size(18);
		color: white;
		@include smooth-transition;
		opacity: 0;
		visibility: hidden;
		letter-spacing: 0.06em;
		@include mq(){

			transform: translateY(0);
			@include font-size(16);
			@include flex(0,0,wrap);
			opacity: 1;
			visibility: visible;
			top: auto;
			left: 18px;
			right: 18px;
			padding: 0;
			bottom: 8px;
			text-align: left;
		}
		span{
			display: block;
			margin-bottom: 10px;
			@include mq(){
				width: 100%;
				order: 2;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
		.c-ft--en{
			@include font-size(12);
			font-weight: 500;
			@include mq(){
				@include font-size(14);
				order: 1;
			}
		}
	}
}



#colorbox{
	@include mq(){
		width: 100%!important;
		height: 100vh !important;
		top: 0 !important;
	}
}
#cboxWrapper{
	@include mq(){
		width: 100% !important;
		@include v-align;
	}
}


#cboxLoadedContent{
	@include mq(){
		width: 100%!important;
		height: auto !important;
	}
	img{
		width: auto !important;
		max-height: 80vh;
		max-width: 100% !important;
		@include mq(){
			width: 100%!important;
			height: 67vw !important;
			@include ob;
		}
	}
}
#cboxContent {
	padding: 60px 74px 50px;
	@include font-size(14);
	color: black;
	@include mq(){
		width: 100% !important;
		padding: 100px 0;
		height: auto !important;
	}
}
#cboxCurrent{
	@include mq(){
		position: absolute;
		bottom: 70px;
		font-size: 12px;
		left: 18px;
	}
}

#cboxNext,#cboxPrevious{
	width: 26px;
	height: 26px;
	@include v-align;
	@include mq(){
		width: 40px;
		height: 40px;
	}
}
#cboxNext{
	background: url(../img/common/next.png) no-repeat;
	background-size: 100%;
	left: auto;
	right: 27px;
	@include smooth-transition;
	@include mq(){
		right: 0;
	}
	&:hover{
		background: url("../img/common/next.png")no-repeat;
		background-size: 100%;
		opacity: 0.7;
	}
}
#cboxPrevious{
	background: url("../img/common/prev.png")no-repeat;
	background-size: 100%;
	left: 27px;
	@include mq(){
		left: 0;
	}
	&:hover{
		background: url("../img/common/prev.png")no-repeat;
		background-size: 100%;
		opacity: 0.7;
		@include smooth-transition;
	}
}
#cboxClose{
	width: 54px;
	height: 41px;
	background: url("../img/common/close.png")no-repeat;
	background-size: 100%;
	top: 19px;
	right: 74px;
	left: auto;
	bottom: auto;
	@include mq(){
		top: 0;
		right: 0;
	}
	&:hover{
		background: url("../img/common/close.png")no-repeat;
		background-size: 100%;
		opacity: 0.7;
		@include smooth-transition;
	}
}