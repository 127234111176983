@use "vars" as *;

.p-about {
    padding: 89px 0 160px;
    @include mq {
        padding: 37px 0 56px;
    }
    &__info {
        padding: 36px 0 22px;
        border-bottom: 1px solid #141414;
        margin-bottom: 119px;
        @include mq {
            padding: 9px 0 22px;
            margin-bottom: 79px;
        }
        &-flex {
            @include flex(start, start, wrap);
        }
        &-col {
            width: 50%;
            padding: 0 15px 0 0;
            letter-spacing: 1.1px;
            @include mq {
                letter-spacing: 0;
                width: 100%;
                padding: 0;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .c-txt {
                &__02 {
                    margin-bottom: 7px;
                    @include mq {
                        margin-bottom: 3px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &__01 {
                    margin-bottom: 23px;
                    @include mq {
                        @include fts(14,24);
                        margin-bottom: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &--other{
                        letter-spacing: 0.3px;
                    }
                }
            }
        }
        &-row {
            @include flex(start, start, wrap);
            span {
                min-width: 112px;
                @include mq {
                    min-width: 98px;
                }
            }
        }
    }
    &__list {
        margin-bottom: 121px;
        @include mq {
            @include fts(14,24);
            margin-bottom: 80px;
        }
        > * {
            @include flex;
            margin-bottom: 3px;
            @include mq {
                margin-bottom: 48px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &-img {
            width: 37.59%;
            @include mq {
                width: 45.49%;
            }
        }
        &-ct {
            width: 62.41%;
            padding: 41px 0 0 15px;
            max-width: 513px;
            margin-left: auto;
            letter-spacing: .06em;
            @include mq {
                width: 54.51%;
                padding: 7px 0 0 16px;
            }
            a {
                color: inherit;
                &[href^='tel'] {
                    margin-left: 5px;
                }
            }
        }
        &-ttl {
            @include fts(20,28);
            letter-spacing: .1em;
            margin-bottom: 21px;
            @include mq {
                @include fts(18,28);
                margin-bottom: 5px;
            }
        }
        &-map {
            font-family: $font-en;
            display: inline-block;
            position: relative;
            &::before {
                position: absolute;
                content: '';
                bottom: 1px;
                left: 0;
                right: 0;
                height: 1px;
                background: #141414;
            }
        }
    }
    &__award {
        margin-bottom: 120px;
        @include mq {
            margin-bottom: 79px;
        }
        &-ct {
            letter-spacing: .06em;
            @include mq(md-min) {
                line-height: 2;
            }
            > * {
                @include flex(start, start, wrap);
                margin-bottom: 22px;
                @include mq {
                    margin-bottom: 28px;
                }
            }
            dt {
                width: 121px;
                letter-spacing: .08em;
                font-weight: 500;
                font-family: $font-en;
                text-align: center;
                @include mq {
                    text-align: left;
                    width: 62px;
                }
            }
            dd {
                width: calc(100% - 121px);
                font-weight: 300;
                @include mq {
                    width: calc(100% - 62px);
                }
            }
            a {
                color: inherit;
                text-decoration: underline;
                text-underline-position: unset;
            }
        }
    }
    &__initiatives {
        &-ct {
            padding: 37px 41px 36px;
            font-weight: 300;
            letter-spacing: .06em;
            @include mq {
                @include fts(14,24);
                padding: 22px 0;
            }
        }
        &-list {
            @include flex(0,0,wrap);
            > * {
                width: calc(100% / 6);
                @include mq(sm) {
                    width: 33.33%;
                }
            }
        }
    }
}