@use "vars" as *;

.p-mv-slider{
	&__item{
		outline: none !important;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		opacity: 1!important;
		transition: opacity 0ms ease 0s !important;
		overflow: hidden;
		img{
			width: 100%;
			height: 100vh;
			@include ob;
			transition: 10s linear;
			@include mq(){
				height: auto;
			}
		}
		&.slick-active{
			.p-mv-slider__img{
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
				opacity: 1;
				transition: 4s linear;
				img{
					transform: scale(1.2) rotate(.1deg);
					transition: 10s linear;
					z-index: 1;
				}
			}
		}
		&.first{
			img{
				-webkit-transform: scale(1) rotate(.1deg);
				-ms-transform: scale(1) rotate(.1deg);
				transform: scale(1) rotate(.1deg);
			}
			&.active{
				img{
					-webkit-transform: scale(1.4) rotate(.1deg);
					-ms-transform: scale(1.4) rotate(.1deg);
					transform: scale(1.4) rotate(.1deg);
					-webkit-transition: 10s linear;
					transition: 10s linear;
					z-index: 1;
				}
			}
		}
		&.prev{
			.p-mv-slider__img{
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
				opacity: 0;
				-webkit-transition: 10s linear;
				transition: 10s linear;
				img{
					-webkit-transform: scale(1.4) rotate(.1deg);
					-ms-transform: scale(1.4) rotate(.1deg);
					transform: scale(1.4) rotate(.1deg);
					-webkit-transition: 10s linear;
					transition: 10s linear;
				}
			}
		}
	}
	&__img{
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		opacity: 0;
		-webkit-transition: 10s;
		transition: 10s;
		position: relative;
		overflow: hidden;
	}
	&__item{

	}
	.slick-dots{
		width: auto;
		left: auto;
		right: 0;
		bottom: 46px;
		@include mq(){
			bottom: 55px;
		}
		li{
			margin: 0 2px;
			&:last-child{
				margin-right: 0;
			}
			button{
				width: 74px;
				height: 4px;
				padding: 0;
				position: relative;
				background: white;
				@include mq(){
					width: 55px;
				}
				&:before{
					display: none;
				}
				&:after{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					width: 0;
					background: #D6D6D6;
				}
			}
			&:nth-child(1){
				button{
					&:after{
						width: 0 !important;
					}
				}
				&.active.slick-active{
					button{
						&:after{
							width: 100% !important;
							transition: ease 10s;
						}
					}
				}
			}
			&.slick-active{
				button{
					&:after{
						width: 100%;
						transition: ease 10s;
					}
				}
			}
		}
	}
}
.js-mv-scrolldown{
	position:absolute;
	left:100px;
	bottom:0;
	height:215px;
	@include mq(){
		height: 85px;
		left: 18px;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;
		width: 3px;
		height: 215px;
		background: white;
		opacity: 0;
		z-index: 9;
		@include mq(md-min){
			animation: pathmove  1.4s ease-in-out infinite;
		}
		@include mq(){
			width: 2px;
			animation: pathmovesp  1.4s ease-in-out infinite;
		}
	}
}
@keyframes pathmove{
	0%{
		height:0;
		top:0;
		opacity: 0;
	}
	30%{
		height:150px;
		opacity: 1;
		@include mq(){
			height: 45px;
		}
	}
	100%{
		height:0;
		top:215px;
		opacity: 0;
	}
}
@keyframes pathmovesp{
	0%{
		height:0;
		top:0;
		opacity: 0;
	}
	30%{
		height: 45px;
		opacity: 1;
	}
	100%{
		height:0;
		top: 85px;
		opacity: 0;
	}
}

.sec-gallery{
	padding: 89px 0;
	@include mq(){
		padding: 36px 0;
	}
}
.p-gallery-list{
	@include flex(0,0,wrap);
	margin: 0 -2px;
	@include mq(){
		margin: 0 -18px 38px;
	}
	&__item{
		width: 33.333%;
		padding: 0 2px;
		margin-bottom: 42px;
		@include mq(){
			width: 50%;
			padding: 0;
		}
		@include mq(sm){
			width: 100%;
			@include mq(){
				margin-bottom: 18px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		a{
			display: block;
			color: #141414;
		}
		&:hover{
			.p-gallery-list__img{
				img{
					-webkit-transform: scale(1.1,1.1);
					-moz-transform: scale(1.1,1.1);
					-o-transform: scale(1.1,1.1);
					-ms-transform: scale(1.1,1.1);
					transform: scale(1.1,1.1);
				}
			}
		}
	}
	&__img{
		position: relative;
		overflow: hidden;
		img{
			width: 100%;
			-webkit-transition: all 0.2s linear;
			-moz-transition: all 0.2s linear;
			-o-transition: all 0.2s linear;
			-ms-transition: all 0.2s linear;
			transition: all 0.2s linear;
		}
		&-new{
			display: block;
			@include font-size(14);
			font-weight: 500;
			background: rgba(#DDDDDD,0.85);
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			padding: 9px 16px;
			@include smooth-transition;
		}
	}
	&__ct{
		@include fts(16,21);
		font-weight: 500;
		padding: 16px 15px;
		letter-spacing: 0.06em;
		@include mq(){
			padding: 7px 16px;
		}
		&-ttl{
			&01{
				display: block;
				padding: 0 100px 0 0;
				position: relative;
				margin-bottom: 13px;
				@include mq(){
					padding: 0 84px 0 0;
					margin-bottom: 9px;
				}
				span{
					font-weight: 500;
					position: absolute;
					top: 1px;
					right: 4px;
				}
			}
			&02{
				display: block;
				color: #919191;
				font-weight: 300;
				@include mq(){
					@include font-size(12);
				}
			}
		}
	}

}
.js-gallery-list--more{
	.p-gallery-list__item{
		display: none;
	}
}

.p-gallery-heading{
	@include flex(center,center,wrap);
	margin-bottom: 55px;
	position: relative;
	z-index: 1;
	@include mq(){
		left: auto;
		margin-bottom: 21px;
	}
	>li{
		margin: 0 80px;
		position: relative;
		@include mq(){
			margin: 0 38px;
		}
		>a{
			display: block;
			@include font-size(24);
			font-weight: 500;
			color: #141414;
			border-bottom: 3px solid;
			padding: 0 0 7px;
			letter-spacing: 2px;
			@include mq(){
				padding: 0 0 3px;
				@include font-size(16);
			}
		}
		&:hover{
			@include mq(md-min){
				.p-gallery-heading__sub{
					display: block;
				}
			}

		}
	}

	&__sub{
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 200px;
		z-index: 1;
		display: none;
		border: 1px solid #E8E8E8;
		@include mq(){

			@include h-align;
			&.active{
				display: block;
			}
		}
		>li{

			position: relative;
			border-top: none;
			background: white;

			>a{
				display: block;
				padding: 7px 15px;
				font-weight: 300;
				@include font-size(16);
				color: #141414;
				&:hover,&.active{
					color: #919191;
				}
			}
			&:hover,&.active{
				>a{
					color: #919191;
				}
				ul{
					display: block;
				}
			}
			ul{
				position: absolute;
				top: 0;
				left: 100%;
				min-width: 200px;
				z-index: 1;
				display: none;
				border: 1px solid #E8E8E8;
				@include mq(){
					display: block;
					width: 100%;
					border: none;
					position: static;
				}
				li{
					background: white;
					a{
						display: block;
						padding: 7px 15px;
						font-weight: 300;
						@include font-size(16);
						color: #141414;
						&:hover,&.active{
							color: #919191;
						}
						@include mq(){
							@include font-size(14);
							padding: 5px 30px;
						}
					}
				}
			}
		}
	}
}

.sec-news{
	padding: 48px 0 160px;
	@include mq(){
		padding: 48px 0 55px;
	}
}
.l-news-block{
	@include flex(0,0,wrap);
	margin-bottom: 151px;
	@include mq(){
		margin-bottom: 78px;
	}
	.c-ttl__01{
		width: 34.5%;
		text-align: right;
		padding: 35px 96px 0 0;
		@include mq(){
			width: 100%;
			padding: 0;
			text-align: left;
		}
	}
	.p-news-block__list{
		width: 65.5%;
		@include mq(){
			width: 100%;
		}
	}
}
.p-news-block{
	&__list{

		&-item{
			margin-bottom: 94px;
			@include mq(){
				margin-bottom: 39px;
			}
			&:last-child{
				margin-bottom: 0;
			}
			a,>div{
				display: block;
				color: #141414;
				@include font-size(16);
				letter-spacing: 0.06em;
				@include mq(){
					@include font-size(14);
					p{
						line-height: 1.7142857143;
					}
				}
				&[href^="#"]:hover{
					opacity: 1;
					cursor: auto;
					pointer-events: none;
				}
			}
		}
		&-date,&-category{
			color: #919191;
			font-weight: 500;
			display: inline-block;
			letter-spacing: 0.5px;
			margin-bottom: 10px;
			@include mq(){
				@include font-size(12);
				margin-bottom: 4px;
			}
		}
		&-date{
			margin-right: 81px;
		}

		&-ttl{
			display: block;
			@include font-size(20);
			border-bottom: 1px solid #141414;
			padding: 0 0 8px;
			margin-bottom: 9px;
			@include mq(){
				@include font-size(16);
				margin-bottom: 4px;
			}
		}
		p{
			font-weight: 300;
		}

	}
}

.js-news-block__list--more{
	.p-news-block__list-item{
		display: none;
	}
}
.c-news-more{
	text-align: right;
	@include mq(){
		text-align: center;
	}
}

.sec-news-page{
	padding: 89px 0 164px;
	@include mq(){
		padding: 38px 0 54px;
	}
	.l-inner{
		max-width: 1004px;
	}
	.p-news-block__list{
		margin-bottom: 142px;
		@include mq(){
			margin-bottom: 33px;
		}
	}
}

.sec-gallery-page {
	padding: 90px 0 164px;
	@include mq(){
		padding: 100px 0 55px;
	}
}