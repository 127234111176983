@use "vars" as *;

.p-policy {
    padding: 159px 0 339px;
    @include mq(xl) {
        padding: 100px 0 200px;
    }
    @include mq {
        padding: 42px 0 88px;
    }
    &__flex {
        max-width: 1236px;
        @include flex(between, start, wrap);
        @include mq(sm) {
            @include mq(xs-min) {
                padding: 0 36px;
            }
        }
    }
    &__ct {
        width: 52.75%;
        max-width: 530px;
        padding: 24px 48px 0;
        @include mq {
            padding: 0 15px 0 0;
        }
        @include mq(sm) {
            width: 100%;
            padding: 0;
            margin-bottom: 38px;
        }
        .c-txt__01 {
            @include mq(md-min) {
                letter-spacing: .15em;
            }
            @include mq {
                @include fts(14,24);
            }
        }
    }
    &__img {
        width: 47.25%;
        @include mq(sm) {
            width: 100%;
        }
    }
}

.p-concept {
    padding: 0 0 160px;
    @include mq(xl) {
        padding: 0 0 100px;
    }
    @include mq {
        padding: 0 0 56px;
    }
    &-list {
        margin-bottom: 192px;
        @include mq(xl) {
            margin-bottom: 100px;
        }
        @include mq {
            margin-bottom: 56px;
        }
        > * {
            &:first-child {
                padding-bottom: 260px;
                @include mq(xl) {
                    padding-bottom: 150px;
                }
                @include mq(sm) {
                    position: relative;
                    z-index: 1;
                    padding: 56px 0 87px;
                    &::before {
                        position: absolute;
                        z-index: -1;
                        content: '';
                        left: 50%;
                        transform: translateX(-50%);
                        background: #F8F8F8;
                        top: 0;
                        bottom: 0;
                        width: 100vw;
                    }
                }
                .p-concept-list {
                    &__flex {
                        max-width: 1140px;
                    }
                    &__img {
                        width: 39.75%;
                        @include mq(sm) {
                            width: calc(68.65% + 36px);
                            margin-left: -36px;
                        }
                        @include mq(xs) {
                            width: calc(67.25% + 18px);
                            margin-left: -18px;
                        }
                    }
                    &__ct {
                        width: calc(60.25% - 15px);
                        max-width: 499px;
                        padding: 96px 0 62px;
                        margin-right: 3px;
                        @include mq(xl) {
                            padding: 30px 0;
                        }
                        @include mq(sm) {
                            width: 100%;
                            max-width: 100%;
                            padding: 42px 0 30px;
                        }
                    }
                }
            }
            &:nth-child(2) {
                padding-bottom: 180px;
                @include mq(xl) {
                    padding-bottom: 100px;
                }
                @include mq(sm) {
                    position: relative;
                    z-index: 1;
                    padding-bottom: 53px;
                    &::before {
                        position: absolute;
                        z-index: -1;
                        content: '';
                        left: 50%;
                        transform: translateX(-50%);
                        background: #F8F8F8;
                        top: 0;
                        bottom: 0;
                        width: 100vw;
                    }
                }
                .p-concept-list {
                    &__flex {
                        flex-direction: row-reverse;
                    }
                    &__img {
                        width: 47.45%;
                        @include mq(sm) {
                            width: 100vw;
                            margin-left: -36px;
                            margin-right: -33px;
                        }
                        @include mq(xs) {
                            margin-left: -18px;
                            margin-right: -18px;
                        }
                    }
                    &__ct {
                        width: calc(52.55% - 70px);
                        margin-right: 0;
                        padding: 30px 0 63px;
                        margin-left: 55px;
                        max-width: 506px;
                        @include mq(ipd) {
                            width: calc(52.55% - 15px);
                            margin-left: 0;
                        }
                        @include mq(sm) {
                            width: 100%;
                            padding: 42px 0 40px;
                            .c-txt__01 {
                                letter-spacing: .11em;
                            }
                        }
                    }
                    &__number {
                        margin-bottom: 45px;
                        @include mq {
                            margin-bottom: 28px;
                        }
                    }
                }
            }
            &:nth-child(3) {
                background: url(../img/concept/img-04.jpg) center/cover;
                color: white;
                margin-bottom: 192px;
                @include mq {
                    margin-bottom: 100px;
                }
                @include mq(sm) {
                    margin-bottom: 66px;
                }
                .p-concept-list {
                    &__flex {
                        display: block;
                        max-width: 1140px;
                        padding-top: 137px;
                        padding-bottom: 134px;
                        @include mq {
                            padding-top: 65px;
                            padding-bottom: 64px;
                        }
                    }
                    &__ct {
                        position: relative;
                        padding: 0 0 225px 0;
                        @include mq {
                            padding: 0 0 40px;
                        }
                        &-img {
                            @include mq(md-min) {
                                position: absolute;
                                right: 66px;
                                top: 53.1%;
                                transform: translateY(-50%);
                                width: calc(328/1104*100%);
                            }
                            @include mq {
                                text-align: center;
                                padding: 5px 3px 0 0;
                            }
                            @include mq(xs) {
                                padding: 0;
                            }
                        }
                        .c-ttl__05,
                        .c-txt__01 {
                            @include mq(md-min) {
                                width: 50%;
                            }
                        }
                        .c-ttl__05 {
                            padding: 0 0 0 5px;
                        }
                    }
                    &__number {
                        margin-bottom: 140px;
                        @include mq(xl) {
                            margin-bottom: 80px;
                        }
                        @include mq {
                            margin-bottom: 29px;
                        }
                    }
                }
            }
            &:nth-child(4) {
                .p-concept-list {
                    &__flex {
                        flex-direction: row-reverse;
                    }
                    &__ct {
                        width: calc(53.6% - 63px);
                        max-width: 452px;
                        padding: 17px 0 62px;
                        margin-right: 48px;
                        @include mq(sm) {
                            width: 100%;
                            max-width: 100%;
                            margin: 0 0 72px;
                            padding: 0 0 33px;
                        }
                        .c-txt__03 {
                            margin-bottom: 14px;
                            @include mq(sm) {
                                margin-bottom: 21px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    &__img {
                        width: 46.4%;
                        @include mq(sm) {
                            width: 100vw;
                            margin-left: -36px;
                            margin-right: -33px;
                        }
                        @include mq(xs) {
                            margin-left: -18px;
                            margin-right: -18px;
                        }
                    }
                }
            }
        }
        &__flex {
            @include flex(between, start, wrap);
            max-width: 1236px;
            @include mq(sm) {
                @include mq(xs-min) {
                    padding: 0 33px 0 36px;
                }
            }
        }
        &__ct {
            border-bottom: 1px solid #C4C4C4;
        }
        &__number {
            font-family: $font-en;
            @include fts(16,19);
            font-weight: 500;
            border-bottom: 1px solid #C4C4C4;
            color: #919191;
            letter-spacing: .07em;
            padding-bottom: 10px;
            margin-bottom: 45px;
            @include mq {
                padding-bottom: 5px;
                margin-bottom: 28px;
            }
        }
        &__text {
            padding: 24px 0 0 16px;
            @include mq {
                padding: 21px 4px 0 0;
            }
        }
        .c-txt {
            &__01, &__03 {
                letter-spacing: 0.06em;
            }
            &__01 {
                margin-bottom: 13px;
                @include mq {
                    @include fts(14,24);
                    margin-bottom: 17px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &__03 {
                margin-bottom: 1px;
                @include mq {
                    margin-bottom: 4px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &-slider {
        .item {
            width: 354px;
            @include mq {
                width: 319px;
            }
            a {
                display: block;
                position: relative;
                color: white;
                &:before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(black,0.53);
                    @include smooth-transition;
                    opacity: 0;
                    visibility: hidden;
                    @include mq(){
                        display: none;
                    }
                }
                &:hover {
                    opacity: 1;
                    &::before, .p-concept-slider__ttl {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            img {
                display: block;
                width: 100%;
            }
        }
        &__ttl{
            display: block;
            position: absolute;
            @include v-align;
            left: 0;
            right: 0;
            padding: 0 5px;
            text-align: center;
            @include font-size(18);
            color: white;
            @include smooth-transition;
            opacity: 0;
            visibility: hidden;
            @include mq(){
    
                transform: translateY(0);
                @include font-size(16);
                @include flex(0,0,wrap);
                opacity: 1;
                visibility: visible;
                top: auto;
                left: 18px;
                right: 18px;
                padding: 0;
                bottom: 8px;
                text-align: left;
            }
            span{
                display: block;
                margin-bottom: 10px;
                @include mq(){
                    width: 100%;
                    order: 2;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .c-ft--en{
                @include font-size(12);
                font-weight: 500;
                @include mq(){
                    @include font-size(14);
                    order: 1;
                }
            }
        }
    }
}
