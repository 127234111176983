@use "vars" as *;


.c-fc{ //font-color
	&--white{
		color: white !important;
	}
	&--black{
		color: black !important;
	}
	&--gray{
		color: #919191 !important;
	}
}



.c-ft{//font-family
	&--en{
		font-family: $font-en;
	}
	&--jp{
		font-family: $font-jp;
	}
}



.c-fw{//font-weight
	&--lt{
		font-weight: 300!important
	}
	&--rg{
		font-weight: 400!important
	}
	&--md{
		font-weight: 500!important
	}
	&--sbd{
		font-weight: 600!important
	}
	&--bd{
		font-weight: 700!important
	}
	&--bk{
		font-weight: 900!important
	}
}



.c-tx--v {
	//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	&-pc{
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}



.c-al{
	&--c{
		text-align: center !important;
	}
	
	&--r{
		text-align: right !important
	}
	
	&--l{
		text-align: left !important;
	}
}



// Image position control
.c-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}










/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt{
	&__01{ //16/28
		@include fts(16,28);
	}
	&__02{ //14/18
		@include fts(14,18);
	}
	&__03{ //18/28
		@include fts(18,28);
		letter-spacing: .08em;
		@include mq {
			@include fts(16,19);
		}
	}
}




//ttl
.c-ttl{
	&__01{
		display: block;
		text-align: center;
		@include font-size(50);
		color: #191919;
		letter-spacing: 4px;
		font-weight: 500;
		line-height: 1;
		margin-bottom: 49px;
		font-family: $font-en;
		@include mq(xl) {
			@include font-size(38);
		}
		@include mq(){
			@include font-size(26);
			letter-spacing: 1.5px;
			margin-bottom: 21px;
		}
	}
	&__02 {
		border-bottom: 1px solid #141414;
		padding-bottom: 12px;
		font-weight: 500;
		> * {
			display: block;
		}
		.jp {
			@include fts(24,28);
			margin-bottom: 4px;
			@include mq {
				@include fts(20,28);
				margin-bottom: 2px;
			}
			span{
				display: inline-block;
				margin: 0 0 0 4px;
				letter-spacing: 4px;
				@include mq(){
					margin: 0 0 0 2px;
					letter-spacing: 2px;
				}
			}
		}
		.c-ft--en {
			@include fts(12,24);
			letter-spacing: .08em;
		}
	}
	&__03 {
		@include fts(32,38);
		font-weight: 500;
		border-bottom: 1px solid #141414;
		padding: 0 0 10px 7px;
		color: #141414;
		@include mq {
			@include fts(24,32);
			padding: 0 0 8px 1px;
		}
		.c-ft--en {
			letter-spacing: .1em;
			color: #191919;
		}
	}
	&__04 {
		overflow: hidden;
		.jp {
			display: inline-block;
			position: relative;
			@include fts(32,40);
			padding: 0 28px 0 0;
			margin-bottom: 10px;
			@include mq(xl) {
				@include font-size(26);
			}
			@include mq {
				@include fts(20,28);
				padding: 0 8px 0 0;
				margin-bottom: -1px;
			}
			&::after {
				position: absolute;
				content: '';
				height: 1px;
				width: 100vw;
				background: #141414;
				left: 100%;
				top: 50%;
				@include mq {
					top: 45%;
				}
			}
		}
		.c-ft--en {
			display: block;
			@include fts(16,19);
			font-weight: 500;
			color: #C4C4C4;
			text-transform: uppercase;
			letter-spacing: .08em;
			padding: 0 2px;
			@include mq(xl) {
				@include font-size(14);
			}
			@include mq {
				@include fts(12,24);
				padding: 0;
			}
		}
	}
	&__05 {
		> * {
			display: block;
		}
		.c-ft--en {
			@include fts(16,19);
			font-weight: 500;
			color: #C4C4C4;
			letter-spacing: .08em;
			margin-bottom: 14px;
			@include mq {
				margin-bottom: 5px;
			}
		}
		.jp {
			@include font-size(40);
			line-height: 1.45;
			letter-spacing: .1em;
			@include mq(xl) {
				@include font-size(30);
			}
			@include mq {
				@include font-size(20);
			}
		}
		&--sm {
			.c-ft--en {
				@include fts(12,24);
				margin-bottom: -4px;
				color: #919191;
			}
			.jp {
				@include mq(xl-min) {
					@include font-size(38);
				}
			}
		}
	}
}

//btn
.c-btn{
	&__01{
		@include flex(center,center,wrap);
		text-align: center;
		width: 100%;
		max-width: 480px;
		height: 123px;
		margin: 0 auto;
		line-height: 1;
		@include font-size(32);
		color: white;
		border: 2px solid white;
		letter-spacing: 1px;
		font-weight: 500;
		@include mq(){
			@include font-size(26);
			letter-spacing: 2.5px;
			max-width: 268px;
			height: 78px;
		}
		&:hover{
			opacity: 1;
			background: white;
			color: #141414;
		}
	}
	&__02{
		display: inline-block;
		border-bottom: 3px solid black;
		@include font-size(18);
		color: #141414;
		font-weight: 500;
		position: relative;
		letter-spacing: 1px;
		overflow: hidden;
		width: 145px;
		height: 35px;
		white-space: nowrap;
		cursor: pointer;
		@include mq(){
			height: 32px;
			@include font-size(16);
		}

		span{
			display: block;
			position: absolute;
			top: 0;
			width: 100%;
			padding: 0 33px 7px 3px;
			transition: all .5s ease;
			&:after{
				content: "";
				width: 16px;
				height: 16px;
				background: url("../img/common/arrow-right.png")no-repeat;
				background-size: 100%;
				background-position: center;
				position: absolute;
				right: 0;
				@include v-align;
			}
		}
		&--first{
			left: 0;
		}
		&--hv{
			left: -100%;
		}
		&:hover{
			opacity: 1;
			color: #919191;
			border-bottom: 3px solid #919191;
			.c-btn__02{
				&--first{
					left: 100% !important;
				}
				&--hv{
					left: 0 !important;
				}
			}
		}
		&--dow{
			span{
				&:after{
					width: 4px;
					height: 16px;
					background: url("../img/common/arrow-dow.png")no-repeat;
					background-size: 100%;
					background-position: center;
					position: absolute;
					right: 10px;
					top: 43%;
				}
			}
			.c-btn__02{
				&--first{

				}
				&--hv{
					top: -100%;
					left: 0 !important;
				}
			}
			&:hover{
				.c-btn__02{
					&--first{
						top: 100%;
						left: 0 !important;
					}
					&--hv{
						top: 0;
					}
				}
			}
		}
	}
	&__03{
		@include flex(center,center,wrap);
		@include font-size(16);
		width: 100%;
		max-width: 302px;
		height: 57px;
		font-weight: 500;
		font-family: $font-base;
		background: #141414;
		color: white;
		border: none;
		outline: none;
		@include smooth-transition;
		cursor: pointer;
		span{
			@include flex(center,center,wrap);
			img{
				display: block;
				margin-right: 16px;
				position: relative;
				top: 2px;
			}
		}
		&:hover{
			background: #001940;
			opacity: 1;
		}
	}
}


//fade up
.js-scrollin {
	opacity: 0;
	-webkit-transform: translate(0, 40px);
	-ms-transform: translate(0, 40px);
	transform: translate(0, 40px);
	&.active{
		opacity: 1;
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		-o-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
}


.hidden{
	display: none !important;
}
