@use "vars" as *;


.l-inner {
	display: block;
	max-width: 1476px;
	padding: 0 18px;
	margin: 0 auto;
	&--1000 {
		max-width: 1036px;
	}
	&--900 {
		max-width: 936px;
	}
}

.l-page-mv {
	&__img {
		img {
			display: block;
			width: 100%;
			height: 275px;
			@include ob;
			@include mq(sm) {
				height: 140px;
			}
		}
	}
}


.l-page-mv-second {
	height: 100vh;
	min-height: 350px;
	position: relative;
	z-index: 1;
	@include flex(center, end);
	@include mq {
		min-height: 300px;
		height: 76.5vh;
	}
	&__img {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img {
			display: block;
			width: 100%;
			height: 100%;
			@include ob;
			// @include mq(){
			// 	height: auto;
			// }
		}
	}
	&__inner {
		width: 100%;
		max-width: 1240px;
		@include mq {
			padding: 0 16px;
		}
	}
	&__ttl {
		position: relative;
		color: white;
		font-weight: 500;
		padding: 4px 0 121px 50px;
		overflow: hidden;
		@include mq(xl) {
			padding: 0 0 80px 35px;
		}
		@include mq {
			padding: 3px 0 28px 20px;
		}
		&::before {
			position: absolute;
			content: "";
			width: 4px;
			top: 0;
			left: 0;
			height: 100%;
			background: #fff;
			animation: pathmove  1.4s ease-in-out infinite;
			@include mq(md-min) {
				box-shadow: 0 0 10px rgba(black, .16);
			}
		}
		> * {
			display: block;
		}
		.c-ft--en {
			@include fts(50,60);
			font-weight: 500;
			text-shadow: 0 0 20px #040606;
			text-transform: uppercase;
			margin-bottom: 10px;
			letter-spacing: .1em;
			@include mq(xl) {
				@include font-size(38);
			}
			@include mq {
				@include fts(26,31);
				margin-bottom: 3px;
				text-shadow: 0 0 6px rgba(#040606, .25);
			}
		}
		.jp {
			@include fts(16,19);
			text-shadow: 0 0 10px #060407;
			letter-spacing: .18em;
			@include mq(xl) {
				@include font-size(14);
			}
			@include mq {
				@include fts(12,18);
				text-shadow: 0 0 6px rgba(#040606, .27);
			}
		}
	}
}

.sec-contact{
	background: url("../img/common/bg-contact.jpg")no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 126px 15px;
	@include mq(){
		padding: 55px 15px 55px;
		background: url("../img/common/bg-contact-sp.jpg")no-repeat;
		background-size: cover;
		background-position: center center;
	}
}



//form input
.form-control{
	display: block;
	width: 100%;
	height: 50px;
	background: white;
	border: 1px solid #DEDEDE;
	font-weight: 300;
	padding: 5px 10px;
	&:focus{
		border-color: #66afe9;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
	}
	&::-webkit-input-placeholder {
		color: #DEDEDE;
	}
	&:-moz-placeholder {
		color: #DEDEDE;
	}
	&::-moz-placeholder {
		color: #DEDEDE;
	}
	&:-ms-input-placeholder {
		color: #DEDEDE;
	}

}

textarea.form-control{
	height: 139px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	resize: none;
}
select.form-control{
	text-overflow: ellipsis;
	white-space: nowrap;
	background: #fff url(../img/common/arrow-dow-02.png) no-repeat;
	background-position: 94% 21px;
	background-size: 10px;
	color: #919191;
	height: auto;
	min-height: 50px;
	&.change{
		color: #141414;
	}

}
.select-wrap {
	position: relative;
}

.select-wrap:focus-within select {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	background: white;
	color: #141414;
}
select option:hover {
	background-color: #919191;
	color: white;
}

//HTML CSS radio end check box



//radio end check box
.pure-list{
	&__input{
		position: relative;
		input{
			position: absolute;
			top:  0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			opacity: 0;
			cursor: pointer;
			&[type="checkbox"]{
				& + span{
					&:after{
						background-color: transparent;
						top: 50%;
						left: 6px;
						width: 12px;
						height: 4px;
						border-style: solid;
						border-color: #919191;
						border-width: 0 0 3px 3px;
						border-image: none;
						transform: rotate(-45deg) scale(0);
					}
				}
				&:checked{
					& + span{
						&:after{
							content: '';
							transform: rotate(-45deg) scale(1);
							transition: transform 200ms ease-out;
						}
						&:before{
							animation: borderscale 200ms ease-in;
						}
					}
				}
			}
			&[type="radio"]{
				+ span{
					&:after,&:before{
						border-radius: 50%;
					}
				}
				&:checked{
					& + span{
						&:before{
							animation: borderscale 300ms ease-in;
							background-color: white;
						}
						&:after{
							transform: scale(1);
						}
					}
				}
			}
		}
		span{
			display: block;
			position: relative;
			padding: 10px 0 10px 40px;
			vertical-align: middle;
			user-select: none;
			cursor: pointer;
			&:before{
				box-sizing: content-box;
				content: '';
				color: #4F8196;
				position: absolute;
				top: 50%;
				left: 0;
				width: 27px;
				height: 27px;
				margin-top: -15px;
				border: 1px solid #DEDEDE;
				text-align: center;
				transition: all 0.4s ease;
			}
			&:after{
				box-sizing: content-box;
				content: '';
				background-color: #919191;
				position: absolute;
				top: 50%;
				left: 4px;
				width: 10px;
				height: 10px;
				margin-top: -5px;
				transform: scale(0);
				transform-origin: 50%;
				transition: transform 200ms ease-out;
			}
		}
	}
}

.wp-pagenavi{
	@include flex(center,center,wrap);
	font-family: $font-en;
	@include font-size(20);
	font-weight: 500;
	@include mq(){
		@include font-size(14);
	}
	span,a{
		display: block;
		padding: 7px 8px;
		color: #141414;
		margin: 0 9px;
		@include mq(){
			padding: 7px;
			margin: 0 3px;
		}
		&:hover{
			color: #919191;
			opacity: 1;
		}
	}
	.current{
		color: #919191;
		border-bottom: 3px solid #919191;
	}
	a{
		border-bottom: 3px solid;
	}
	span{
		letter-spacing: 3px;
	}
}

#page-404{
	padding: 150px 0 0;
	min-height: calc(100vh - 303px);
	@include mq(){
		padding: 100px 0 0;
		min-height: calc(100vh - 537px);
	}
}

.loading{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999999;
	background: #E3E3E3;
	@include flex(center,center,wrap);
	transition: all 0.7s ease;
	&-logo{
		display: block;
		width: 420px;
		opacity: 0;
		visibility: hidden;
		transition: all .5s ease;
		transition-delay: 0.5s;
		transform: translate(0, 40px);
		@include mq(sm){
			width: 300px;
		}
	}
}

.is-hello{
	.loading{
		opacity: 1;
		visibility: visible;
		&-logo{
			opacity: 1;
			visibility: visible;
			transform: translate(0, 0);
		}
	}
	&.hidden-loading{
		.loading{
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}
	}
}
.first-load{
	.loading{
		display: none;
	}
}