@use "vars" as *;

#header{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	@include flex(bet,ween,wrap);
	padding: 24px 0 24px 55px;
	@include smooth-transition;
	@include mq(){
		padding: 0;
	}
	.l-header-logo{
		display: block;
		width: 448px;
		@include mq(){
			width: 275px;
			&.off{
				display: none;
			}
		}
	}
	.l-header-nav{
		position: fixed;
		top: 0;
		right: -580px;
		bottom: 0;
		width: 580px;
		background: rgba(black,0.8);
		display: flex;
		flex-wrap: wrap;
		align-content: space-between;
		color: white;
		font-weight: 500;
		@include font-size(16);
		letter-spacing: 0.5px;
		padding: 100px 80px 40px;
		@include smooth-transition;
		@include mq(sm){
			width: 100%;
			right: -100%;
			padding: 65px 30px 65px;
		}
		&.active{
			right: 0;
		}
		a{
			color: white;
		}
		&__menu{
			width: 100%;
			&-item{
				a{
					display: block;
					padding: 15px 0;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
		&__blog{
			width: 100%;
			&-instagram{
				@include flex(0,center,wrap);
				margin-bottom: 20px;
				@include mq(){
					margin-bottom: 15px;
				}
				span{
					display: block;
					margin-right: 64px;
					@include mq(){
						margin-right: 50px;
					}
				}
				img{
					width: 29px;
				}
			}
			&-logo{
				display: block;
				max-width: 270px;
				@include mq(){
					max-width: 225px;
				}
			}
		}
	}
	.l-header-language{
		@include flex(center,center,wrap);
		position: absolute;
		@include v-align;
		right: 148px;
		z-index: 99;
		@include smooth-transition;
		@include mq(){
			transform: translateY(0);
			right: 15px;
			top: 36px;
		}
		&__item{
			a{
				display: block;
				@include font-size(14);
				padding: 10px 18px;
				color: #141414;
				font-weight: 500;
				position: relative;
				@include mq(){
					@include font-size(12);
					padding: 6px 7px;
				}
				&:before{
					content: "";
					width: 1px;
					height: 12px;
					background: #141414;
					position: absolute;
					right: 0;
					@include v-align;
				}
				&:after{
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 0;
					max-width: 27px;
					margin: 0 auto;
					background: #141414;
					@include smooth-transition;
					@include mq(){
						left: 3px;
						right: 3px;
					}
				}
			}
			&:last-child{
				a{
					&:before{
						display: none;
					}
				}
			}
			&.active,&:hover{
				a{
					opacity: 1;
					&:after{
						height: 2px;
					}
				}
			}
		}
		&.active{
			li{
				a{
					color: white;
					&:before,&:after{
						background: white;
					}
				}
			}
		}
	}
	.js-mobile {
		width: 56px;
		height: 56px;
		border-radius: 50%;
		position: absolute;
		right: 60px;
		@include v-align;
		cursor: pointer;
		z-index: 99;

		opacity: 0 ;
		visibility: hidden;
		@include flex(center,center);
		@include smooth-transition;
		@include mq(){
			width: 50px;
			height: 30px;
			background: none;
			right: 18px;
			top: 28px;
		}
		span{
			position: absolute;
			display: block;
			width: 30px;
			height: 2px;
			margin: 0 auto;
			background-color: transparent;
			border-radius: 3px;
			z-index: 1;
			@include mq(){
				width: 100%;
			}
			&:before,&:after{
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background: white;
				content: '';
				-webkit-transition: -webkit-transform 0.3s;
				transition: transform 0.3s;
				border-radius: 3px;
				@include mq(){
					background: black;
				}
			}
			&:before{
				-webkit-transform: translateY(-250%);
				transform: translateY(-250%);
				top: 1px;
			}
			&:after{
				-webkit-transform: translateY(250%);
				transform: translateY(250%);
				bottom: 1px;
			}
		}
		&--close {
			&:after{
				background: none;
				border: 1px solid white;
				@include mq(){
					border: none;
				}
			}
			span{
				background-color: transparent;
				&:before,&:after{
					width: 100%;
					background: white;
					@include mq(){

					}
				}
				&:before {
					-webkit-transform: translateY(0) rotate(45deg);
					transform: translateY(0) rotate(20deg);
					top: 0;
					@include mq(){
						transform: translateY(0) rotate(15deg);
					}
				}
				&:after {
					-webkit-transform: translateY(0) rotate(-45deg);
					transform: translateY(0) rotate(-20deg);
					bottom: 0;
					@include mq(){
						transform: translateY(0) rotate(-15deg);
					}
				}
			}
		}
		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			background: #141414;
			@include smooth-transition;
			@include mq(){
				display: none;
			}
		}
		&:hover{
			&:after{
				transform: scale(1.2,1.2);
			}
		}
	}
	&.js-fixed{
		//background: white;
		//box-shadow: 0 0 16px 0 rgba(black,0.4);
		@include mq(md-min){
			padding: 15px;
			.js-mobile{
				right: 30px !important;
			}
			.l-header-language{
				right: 100px;
			}
		}
		.l-header-logo{
			opacity: 0;
		}
		@include mq(){
			padding: 4px 0 12px;
			.js-mobile{
				top: 20px !important;
			}
			.l-header-language{
				top: 28px;
			}
		}
	}
}

.is-hello{
	#header{
		.js-mobile{
			opacity: 1;
			visibility: visible;
			@include mq(md-min){
				right: 79px;
			}
		}
	}

}

.js-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	body {
		-webkit-overflow-scrolling: auto;
	}
	#header{
		z-index: 99999;
	}
}