@use "vars" as *;

#footer{
	background: rgba(black,0.83);
	color: white;
	padding: 49px 0 20px;
	@include mq(){
		padding: 45px 0 20px;
	}
	.l-inner{
		@include flex(between,start,wrap);
	}
	.l-footer-blog{
		width: 410px;
		@include mq(){
			order: 2;
			width: 100%;
			max-width: 230px;
			margin: 0 auto 13px;
		}
		&__logo{
			display: block;
			max-width: 339px;
			margin-bottom: 31px;
			@include mq(){

				margin-bottom: 22px;
			}
		}
		&__instagram{
			@include flex(0,center,wrap);
			color: white;
			@include font-size(16);
			font-weight: 500;
			@include mq(){
				justify-content: center;
			}
			span{
				display: block;
				margin-right: 51px;

			}
			img{
				width: 29px;
			}
		}
	}
	.l-footer-menu{
		width: calc(100% - 410px);
		@include flex(end,0,wrap);
		margin-bottom: 140px;
		@include mq(){
			order: 1;
			width: 100%;
			margin-bottom: 38px;
		}
		&__item{
			padding: 0 34px;
			margin-bottom: 24px;
			@include mq(){
				width: 100%;
				padding: 0;
				text-align: center;

				&:last-child{
					margin-bottom: 0;
				}
			}
			&:nth-child(1){
				padding-left: 0;
			}
			&:last-child{
				@include mq(xl-min){
					padding-right: 0;
				}
			}
			a{
				display: block;
				color: white;
				@include font-size(16);
				letter-spacing: 0.5px;
				font-weight: 500;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	.copyright{
		width: 100%;
		@include flex(end,0,wrap);
		@include fts(14,24);
		font-weight: 500;
		padding: 0 28px 0 0;
		letter-spacing: 0.7px;
		@include mq(){
			order: 3;
			text-align: center;
			@include fts(12,18);
			padding: 0;
			display: block;
			letter-spacing: 1.3px;
		}
	}
}