@charset "UTF-8";
/* ====================================================
Font
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px; /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Media Quary
==================================================== */
/* ====================================================
// flexセット
==================================================== */
/* ====================================================
画像などを縦横比を維持したままレスポンシブ対応
*画像やGoogle Map、YouTubeの埋め込みなどを縦横比を維持したままレスポンシブ対応
.photo {
  // 画像を16:9にトリミングして縦横比を常に保つ
  @include aspect-ratio(16, 9);

  & > .image {
    @include object-fit(cover);
  }
}
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
.mb0 {
  margin-bottom: 0 !important;
}

body {
  color: #141414;
  font-family: "Noto Sans JP", sans-serif;
  font-feature-settings: "palt" 1;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  opacity: 0.7;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}
@media screen and (min-width: 835px) {
  a[href^=tel] {
    pointer-events: none;
  }
}

.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}
@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}
@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media screen and (min-width: 835px) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

.c-fc--white {
  color: white !important;
}
.c-fc--black {
  color: black !important;
}
.c-fc--gray {
  color: #919191 !important;
}

.c-ft--en {
  font-family: "Open Sans", sans-serif;
}
.c-ft--jp {
  font-family: "Noto Serif JP", serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left !important;
}

.c-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.c-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  font-size: 1.6rem;
  line-height: 1.75;
}
.c-txt__02 {
  font-size: 1.4rem;
  line-height: 1.2857142857;
}
.c-txt__03 {
  font-size: 1.8rem;
  line-height: 1.5555555556;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 834px) {
  .c-txt__03 {
    font-size: 1.6rem;
    line-height: 1.1875;
  }
}

.c-ttl__01 {
  display: block;
  text-align: center;
  font-size: 5rem;
  color: #191919;
  letter-spacing: 4px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 49px;
  font-family: "Open Sans", sans-serif;
}
@media screen and (max-width: 1245px) {
  .c-ttl__01 {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__01 {
    font-size: 2.6rem;
    letter-spacing: 1.5px;
    margin-bottom: 21px;
  }
}
.c-ttl__02 {
  border-bottom: 1px solid #141414;
  padding-bottom: 12px;
  font-weight: 500;
}
.c-ttl__02 > * {
  display: block;
}
.c-ttl__02 .jp {
  font-size: 2.4rem;
  line-height: 1.1666666667;
  margin-bottom: 4px;
}
@media screen and (max-width: 834px) {
  .c-ttl__02 .jp {
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 2px;
  }
}
.c-ttl__02 .jp span {
  display: inline-block;
  margin: 0 0 0 4px;
  letter-spacing: 4px;
}
@media screen and (max-width: 834px) {
  .c-ttl__02 .jp span {
    margin: 0 0 0 2px;
    letter-spacing: 2px;
  }
}
.c-ttl__02 .c-ft--en {
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0.08em;
}
.c-ttl__03 {
  font-size: 3.2rem;
  line-height: 1.1875;
  font-weight: 500;
  border-bottom: 1px solid #141414;
  padding: 0 0 10px 7px;
  color: #141414;
}
@media screen and (max-width: 834px) {
  .c-ttl__03 {
    font-size: 2.4rem;
    line-height: 1.3333333333;
    padding: 0 0 8px 1px;
  }
}
.c-ttl__03 .c-ft--en {
  letter-spacing: 0.1em;
  color: #191919;
}
.c-ttl__04 {
  overflow: hidden;
}
.c-ttl__04 .jp {
  display: inline-block;
  position: relative;
  font-size: 3.2rem;
  line-height: 1.25;
  padding: 0 28px 0 0;
  margin-bottom: 10px;
}
@media screen and (max-width: 1245px) {
  .c-ttl__04 .jp {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__04 .jp {
    font-size: 2rem;
    line-height: 1.4;
    padding: 0 8px 0 0;
    margin-bottom: -1px;
  }
}
.c-ttl__04 .jp::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100vw;
  background: #141414;
  left: 100%;
  top: 50%;
}
@media screen and (max-width: 834px) {
  .c-ttl__04 .jp::after {
    top: 45%;
  }
}
.c-ttl__04 .c-ft--en {
  display: block;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: 500;
  color: #C4C4C4;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  padding: 0 2px;
}
@media screen and (max-width: 1245px) {
  .c-ttl__04 .c-ft--en {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__04 .c-ft--en {
    font-size: 1.2rem;
    line-height: 2;
    padding: 0;
  }
}
.c-ttl__05 > * {
  display: block;
}
.c-ttl__05 .c-ft--en {
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: 500;
  color: #C4C4C4;
  letter-spacing: 0.08em;
  margin-bottom: 14px;
}
@media screen and (max-width: 834px) {
  .c-ttl__05 .c-ft--en {
    margin-bottom: 5px;
  }
}
.c-ttl__05 .jp {
  font-size: 4rem;
  line-height: 1.45;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 1245px) {
  .c-ttl__05 .jp {
    font-size: 3rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__05 .jp {
    font-size: 2rem;
  }
}
.c-ttl__05--sm .c-ft--en {
  font-size: 1.2rem;
  line-height: 2;
  margin-bottom: -4px;
  color: #919191;
}
@media screen and (min-width: 1246px) {
  .c-ttl__05--sm .jp {
    font-size: 3.8rem;
  }
}

.c-btn__01 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  max-width: 480px;
  height: 123px;
  margin: 0 auto;
  line-height: 1;
  font-size: 3.2rem;
  color: white;
  border: 2px solid white;
  letter-spacing: 1px;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    font-size: 2.6rem;
    letter-spacing: 2.5px;
    max-width: 268px;
    height: 78px;
  }
}
.c-btn__01:hover {
  opacity: 1;
  background: white;
  color: #141414;
}
.c-btn__02 {
  display: inline-block;
  border-bottom: 3px solid black;
  font-size: 1.8rem;
  color: #141414;
  font-weight: 500;
  position: relative;
  letter-spacing: 1px;
  overflow: hidden;
  width: 145px;
  height: 35px;
  white-space: nowrap;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .c-btn__02 {
    height: 32px;
    font-size: 1.6rem;
  }
}
.c-btn__02 span {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 33px 7px 3px;
  transition: all 0.5s ease;
}
.c-btn__02 span:after {
  content: "";
  width: 16px;
  height: 16px;
  background: url("../img/common/arrow-right.png") no-repeat;
  background-size: 100%;
  background-position: center;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.c-btn__02--first {
  left: 0;
}
.c-btn__02--hv {
  left: -100%;
}
.c-btn__02:hover {
  opacity: 1;
  color: #919191;
  border-bottom: 3px solid #919191;
}
.c-btn__02:hover .c-btn__02--first {
  left: 100% !important;
}
.c-btn__02:hover .c-btn__02--hv {
  left: 0 !important;
}
.c-btn__02--dow span:after {
  width: 4px;
  height: 16px;
  background: url("../img/common/arrow-dow.png") no-repeat;
  background-size: 100%;
  background-position: center;
  position: absolute;
  right: 10px;
  top: 43%;
}
.c-btn__02--dow .c-btn__02--hv {
  top: -100%;
  left: 0 !important;
}
.c-btn__02--dow:hover .c-btn__02--first {
  top: 100%;
  left: 0 !important;
}
.c-btn__02--dow:hover .c-btn__02--hv {
  top: 0;
}
.c-btn__03 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.6rem;
  width: 100%;
  max-width: 302px;
  height: 57px;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
  background: #141414;
  color: white;
  border: none;
  outline: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.c-btn__03 span {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.c-btn__03 span img {
  display: block;
  margin-right: 16px;
  position: relative;
  top: 2px;
}
.c-btn__03:hover {
  background: #001940;
  opacity: 1;
}

.js-scrollin {
  opacity: 0;
  -webkit-transform: translate(0, 40px);
  -ms-transform: translate(0, 40px);
  transform: translate(0, 40px);
}
.js-scrollin.active {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.hidden {
  display: none !important;
}

.l-inner {
  display: block;
  max-width: 1476px;
  padding: 0 18px;
  margin: 0 auto;
}
.l-inner--1000 {
  max-width: 1036px;
}
.l-inner--900 {
  max-width: 936px;
}

.l-page-mv__img img {
  display: block;
  width: 100%;
  height: 275px;
  object-fit: cover;
  font-family: "object-fit: cover";
}
@media screen and (max-width: 640px) {
  .l-page-mv__img img {
    height: 140px;
  }
}

.l-page-mv-second {
  height: 100vh;
  min-height: 350px;
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@media screen and (max-width: 834px) {
  .l-page-mv-second {
    min-height: 300px;
    height: 76.5vh;
  }
}
.l-page-mv-second__img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.l-page-mv-second__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
.l-page-mv-second__inner {
  width: 100%;
  max-width: 1240px;
}
@media screen and (max-width: 834px) {
  .l-page-mv-second__inner {
    padding: 0 16px;
  }
}
.l-page-mv-second__ttl {
  position: relative;
  color: white;
  font-weight: 500;
  padding: 4px 0 121px 50px;
  overflow: hidden;
}
@media screen and (max-width: 1245px) {
  .l-page-mv-second__ttl {
    padding: 0 0 80px 35px;
  }
}
@media screen and (max-width: 834px) {
  .l-page-mv-second__ttl {
    padding: 3px 0 28px 20px;
  }
}
.l-page-mv-second__ttl::before {
  position: absolute;
  content: "";
  width: 4px;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff;
  animation: pathmove 1.4s ease-in-out infinite;
}
@media screen and (min-width: 835px) {
  .l-page-mv-second__ttl::before {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  }
}
.l-page-mv-second__ttl > * {
  display: block;
}
.l-page-mv-second__ttl .c-ft--en {
  font-size: 5rem;
  line-height: 1.2;
  font-weight: 500;
  text-shadow: 0 0 20px #040606;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 1245px) {
  .l-page-mv-second__ttl .c-ft--en {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 834px) {
  .l-page-mv-second__ttl .c-ft--en {
    font-size: 2.6rem;
    line-height: 1.1923076923;
    margin-bottom: 3px;
    text-shadow: 0 0 6px rgba(4, 6, 6, 0.25);
  }
}
.l-page-mv-second__ttl .jp {
  font-size: 1.6rem;
  line-height: 1.1875;
  text-shadow: 0 0 10px #060407;
  letter-spacing: 0.18em;
}
@media screen and (max-width: 1245px) {
  .l-page-mv-second__ttl .jp {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 834px) {
  .l-page-mv-second__ttl .jp {
    font-size: 1.2rem;
    line-height: 1.5;
    text-shadow: 0 0 6px rgba(4, 6, 6, 0.27);
  }
}

.sec-contact {
  background: url("../img/common/bg-contact.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 126px 15px;
}
@media screen and (max-width: 834px) {
  .sec-contact {
    padding: 55px 15px 55px;
    background: url("../img/common/bg-contact-sp.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 50px;
  background: white;
  border: 1px solid #DEDEDE;
  font-weight: 300;
  padding: 5px 10px;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-webkit-input-placeholder {
  color: #DEDEDE;
}
.form-control:-moz-placeholder {
  color: #DEDEDE;
}
.form-control::-moz-placeholder {
  color: #DEDEDE;
}
.form-control:-ms-input-placeholder {
  color: #DEDEDE;
}

textarea.form-control {
  height: 139px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

select.form-control {
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #fff url(../img/common/arrow-dow-02.png) no-repeat;
  background-position: 94% 21px;
  background-size: 10px;
  color: #919191;
  height: auto;
  min-height: 50px;
}
select.form-control.change {
  color: #141414;
}

.select-wrap {
  position: relative;
}

.select-wrap:focus-within select {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: white;
  color: #141414;
}

select option:hover {
  background-color: #919191;
  color: white;
}

.pure-list__input {
  position: relative;
}
.pure-list__input input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.pure-list__input input[type=checkbox] + span:after {
  background-color: transparent;
  top: 50%;
  left: 6px;
  width: 12px;
  height: 4px;
  border-style: solid;
  border-color: #919191;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
.pure-list__input input[type=checkbox]:checked + span:after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.pure-list__input input[type=checkbox]:checked + span:before {
  animation: borderscale 200ms ease-in;
}
.pure-list__input input[type=radio] + span:after, .pure-list__input input[type=radio] + span:before {
  border-radius: 50%;
}
.pure-list__input input[type=radio]:checked + span:before {
  animation: borderscale 300ms ease-in;
  background-color: white;
}
.pure-list__input input[type=radio]:checked + span:after {
  transform: scale(1);
}
.pure-list__input span {
  display: block;
  position: relative;
  padding: 10px 0 10px 40px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.pure-list__input span:before {
  box-sizing: content-box;
  content: "";
  color: #4F8196;
  position: absolute;
  top: 50%;
  left: 0;
  width: 27px;
  height: 27px;
  margin-top: -15px;
  border: 1px solid #DEDEDE;
  text-align: center;
  transition: all 0.4s ease;
}
.pure-list__input span:after {
  box-sizing: content-box;
  content: "";
  background-color: #919191;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.wp-pagenavi {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi {
    font-size: 1.4rem;
  }
}
.wp-pagenavi span, .wp-pagenavi a {
  display: block;
  padding: 7px 8px;
  color: #141414;
  margin: 0 9px;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi span, .wp-pagenavi a {
    padding: 7px;
    margin: 0 3px;
  }
}
.wp-pagenavi span:hover, .wp-pagenavi a:hover {
  color: #919191;
  opacity: 1;
}
.wp-pagenavi .current {
  color: #919191;
  border-bottom: 3px solid #919191;
}
.wp-pagenavi a {
  border-bottom: 3px solid;
}
.wp-pagenavi span {
  letter-spacing: 3px;
}

#page-404 {
  padding: 150px 0 0;
  min-height: calc(100vh - 303px);
}
@media screen and (max-width: 834px) {
  #page-404 {
    padding: 100px 0 0;
    min-height: calc(100vh - 537px);
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: #E3E3E3;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  transition: all 0.7s ease;
}
.loading-logo {
  display: block;
  width: 420px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  transition-delay: 0.5s;
  transform: translate(0, 40px);
}
@media screen and (max-width: 640px) {
  .loading-logo {
    width: 300px;
  }
}

.is-hello .loading {
  opacity: 1;
  visibility: visible;
}
.is-hello .loading-logo {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.is-hello.hidden-loading .loading {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.first-load .loading {
  display: none;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 24px 0 24px 55px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  #header {
    padding: 0;
  }
}
#header .l-header-logo {
  display: block;
  width: 448px;
}
@media screen and (max-width: 834px) {
  #header .l-header-logo {
    width: 275px;
  }
  #header .l-header-logo.off {
    display: none;
  }
}
#header .l-header-nav {
  position: fixed;
  top: 0;
  right: -580px;
  bottom: 0;
  width: 580px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  color: white;
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  padding: 100px 80px 40px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 640px) {
  #header .l-header-nav {
    width: 100%;
    right: -100%;
    padding: 65px 30px 65px;
  }
}
#header .l-header-nav.active {
  right: 0;
}
#header .l-header-nav a {
  color: white;
}
#header .l-header-nav__menu {
  width: 100%;
}
#header .l-header-nav__menu-item a {
  display: block;
  padding: 15px 0;
}
#header .l-header-nav__menu-item a:hover {
  text-decoration: underline;
}
#header .l-header-nav__blog {
  width: 100%;
}
#header .l-header-nav__blog-instagram {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media screen and (max-width: 834px) {
  #header .l-header-nav__blog-instagram {
    margin-bottom: 15px;
  }
}
#header .l-header-nav__blog-instagram span {
  display: block;
  margin-right: 64px;
}
@media screen and (max-width: 834px) {
  #header .l-header-nav__blog-instagram span {
    margin-right: 50px;
  }
}
#header .l-header-nav__blog-instagram img {
  width: 29px;
}
#header .l-header-nav__blog-logo {
  display: block;
  max-width: 270px;
}
@media screen and (max-width: 834px) {
  #header .l-header-nav__blog-logo {
    max-width: 225px;
  }
}
#header .l-header-language {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 148px;
  z-index: 99;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  #header .l-header-language {
    transform: translateY(0);
    right: 15px;
    top: 36px;
  }
}
#header .l-header-language__item a {
  display: block;
  font-size: 1.4rem;
  padding: 10px 18px;
  color: #141414;
  font-weight: 500;
  position: relative;
}
@media screen and (max-width: 834px) {
  #header .l-header-language__item a {
    font-size: 1.2rem;
    padding: 6px 7px;
  }
}
#header .l-header-language__item a:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #141414;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
#header .l-header-language__item a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  max-width: 27px;
  margin: 0 auto;
  background: #141414;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  #header .l-header-language__item a:after {
    left: 3px;
    right: 3px;
  }
}
#header .l-header-language__item:last-child a:before {
  display: none;
}
#header .l-header-language__item.active a, #header .l-header-language__item:hover a {
  opacity: 1;
}
#header .l-header-language__item.active a:after, #header .l-header-language__item:hover a:after {
  height: 2px;
}
#header .l-header-language.active li a {
  color: white;
}
#header .l-header-language.active li a:before, #header .l-header-language.active li a:after {
  background: white;
}
#header .js-mobile {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: absolute;
  right: 60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  #header .js-mobile {
    width: 50px;
    height: 30px;
    background: none;
    right: 18px;
    top: 28px;
  }
}
#header .js-mobile span {
  position: absolute;
  display: block;
  width: 30px;
  height: 2px;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 3px;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  #header .js-mobile span {
    width: 100%;
  }
}
#header .js-mobile span:before, #header .js-mobile span:after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  content: "";
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  border-radius: 3px;
}
@media screen and (max-width: 834px) {
  #header .js-mobile span:before, #header .js-mobile span:after {
    background: black;
  }
}
#header .js-mobile span:before {
  -webkit-transform: translateY(-250%);
  transform: translateY(-250%);
  top: 1px;
}
#header .js-mobile span:after {
  -webkit-transform: translateY(250%);
  transform: translateY(250%);
  bottom: 1px;
}
#header .js-mobile--close:after {
  background: none;
  border: 1px solid white;
}
@media screen and (max-width: 834px) {
  #header .js-mobile--close:after {
    border: none;
  }
}
#header .js-mobile--close span {
  background-color: transparent;
}
#header .js-mobile--close span:before, #header .js-mobile--close span:after {
  width: 100%;
  background: white;
}
#header .js-mobile--close span:before {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(20deg);
  top: 0;
}
@media screen and (max-width: 834px) {
  #header .js-mobile--close span:before {
    transform: translateY(0) rotate(15deg);
  }
}
#header .js-mobile--close span:after {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-20deg);
  bottom: 0;
}
@media screen and (max-width: 834px) {
  #header .js-mobile--close span:after {
    transform: translateY(0) rotate(-15deg);
  }
}
#header .js-mobile:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #141414;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  #header .js-mobile:after {
    display: none;
  }
}
#header .js-mobile:hover:after {
  transform: scale(1.2, 1.2);
}
@media screen and (min-width: 835px) {
  #header.js-fixed {
    padding: 15px;
  }
  #header.js-fixed .js-mobile {
    right: 30px !important;
  }
  #header.js-fixed .l-header-language {
    right: 100px;
  }
}
#header.js-fixed .l-header-logo {
  opacity: 0;
}
@media screen and (max-width: 834px) {
  #header.js-fixed {
    padding: 4px 0 12px;
  }
  #header.js-fixed .js-mobile {
    top: 20px !important;
  }
  #header.js-fixed .l-header-language {
    top: 28px;
  }
}

.is-hello #header .js-mobile {
  opacity: 1;
  visibility: visible;
}
@media screen and (min-width: 835px) {
  .is-hello #header .js-mobile {
    right: 79px;
  }
}

.js-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.js-locked body {
  -webkit-overflow-scrolling: auto;
}
.js-locked #header {
  z-index: 99999;
}

#footer {
  background: rgba(0, 0, 0, 0.83);
  color: white;
  padding: 49px 0 20px;
}
@media screen and (max-width: 834px) {
  #footer {
    padding: 45px 0 20px;
  }
}
#footer .l-inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#footer .l-footer-blog {
  width: 410px;
}
@media screen and (max-width: 834px) {
  #footer .l-footer-blog {
    order: 2;
    width: 100%;
    max-width: 230px;
    margin: 0 auto 13px;
  }
}
#footer .l-footer-blog__logo {
  display: block;
  max-width: 339px;
  margin-bottom: 31px;
}
@media screen and (max-width: 834px) {
  #footer .l-footer-blog__logo {
    margin-bottom: 22px;
  }
}
#footer .l-footer-blog__instagram {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: white;
  font-size: 1.6rem;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  #footer .l-footer-blog__instagram {
    justify-content: center;
  }
}
#footer .l-footer-blog__instagram span {
  display: block;
  margin-right: 51px;
}
#footer .l-footer-blog__instagram img {
  width: 29px;
}
#footer .l-footer-menu {
  width: calc(100% - 410px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 140px;
}
@media screen and (max-width: 834px) {
  #footer .l-footer-menu {
    order: 1;
    width: 100%;
    margin-bottom: 38px;
  }
}
#footer .l-footer-menu__item {
  padding: 0 34px;
  margin-bottom: 24px;
}
@media screen and (max-width: 834px) {
  #footer .l-footer-menu__item {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  #footer .l-footer-menu__item:last-child {
    margin-bottom: 0;
  }
}
#footer .l-footer-menu__item:nth-child(1) {
  padding-left: 0;
}
@media screen and (min-width: 1246px) {
  #footer .l-footer-menu__item:last-child {
    padding-right: 0;
  }
}
#footer .l-footer-menu__item a {
  display: block;
  color: white;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  font-weight: 500;
}
#footer .l-footer-menu__item a:hover {
  text-decoration: underline;
}
#footer .copyright {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.4rem;
  line-height: 1.7142857143;
  font-weight: 500;
  padding: 0 28px 0 0;
  letter-spacing: 0.7px;
}
@media screen and (max-width: 834px) {
  #footer .copyright {
    order: 3;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 0;
    display: block;
    letter-spacing: 1.3px;
  }
}

.p-mv-slider__item {
  outline: none !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1 !important;
  transition: opacity 0ms ease 0s !important;
  overflow: hidden;
}
.p-mv-slider__item img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  font-family: "object-fit: cover";
  transition: 10s linear;
}
@media screen and (max-width: 834px) {
  .p-mv-slider__item img {
    height: auto;
  }
}
.p-mv-slider__item.slick-active .p-mv-slider__img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
  transition: 4s linear;
}
.p-mv-slider__item.slick-active .p-mv-slider__img img {
  transform: scale(1.2) rotate(0.1deg);
  transition: 10s linear;
  z-index: 1;
}
.p-mv-slider__item.first img {
  -webkit-transform: scale(1) rotate(0.1deg);
  -ms-transform: scale(1) rotate(0.1deg);
  transform: scale(1) rotate(0.1deg);
}
.p-mv-slider__item.first.active img {
  -webkit-transform: scale(1.4) rotate(0.1deg);
  -ms-transform: scale(1.4) rotate(0.1deg);
  transform: scale(1.4) rotate(0.1deg);
  -webkit-transition: 10s linear;
  transition: 10s linear;
  z-index: 1;
}
.p-mv-slider__item.prev .p-mv-slider__img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  -webkit-transition: 10s linear;
  transition: 10s linear;
}
.p-mv-slider__item.prev .p-mv-slider__img img {
  -webkit-transform: scale(1.4) rotate(0.1deg);
  -ms-transform: scale(1.4) rotate(0.1deg);
  transform: scale(1.4) rotate(0.1deg);
  -webkit-transition: 10s linear;
  transition: 10s linear;
}
.p-mv-slider__img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  -webkit-transition: 10s;
  transition: 10s;
  position: relative;
  overflow: hidden;
}
.p-mv-slider .slick-dots {
  width: auto;
  left: auto;
  right: 0;
  bottom: 46px;
}
@media screen and (max-width: 834px) {
  .p-mv-slider .slick-dots {
    bottom: 55px;
  }
}
.p-mv-slider .slick-dots li {
  margin: 0 2px;
}
.p-mv-slider .slick-dots li:last-child {
  margin-right: 0;
}
.p-mv-slider .slick-dots li button {
  width: 74px;
  height: 4px;
  padding: 0;
  position: relative;
  background: white;
}
@media screen and (max-width: 834px) {
  .p-mv-slider .slick-dots li button {
    width: 55px;
  }
}
.p-mv-slider .slick-dots li button:before {
  display: none;
}
.p-mv-slider .slick-dots li button:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background: #D6D6D6;
}
.p-mv-slider .slick-dots li:nth-child(1) button:after {
  width: 0 !important;
}
.p-mv-slider .slick-dots li:nth-child(1).active.slick-active button:after {
  width: 100% !important;
  transition: ease 10s;
}
.p-mv-slider .slick-dots li.slick-active button:after {
  width: 100%;
  transition: ease 10s;
}

.js-mv-scrolldown {
  position: absolute;
  left: 100px;
  bottom: 0;
  height: 215px;
}
@media screen and (max-width: 834px) {
  .js-mv-scrolldown {
    height: 85px;
    left: 18px;
  }
}
.js-mv-scrolldown:after {
  content: "";
  position: absolute;
  top: 0;
  width: 3px;
  height: 215px;
  background: white;
  opacity: 0;
  z-index: 9;
}
@media screen and (min-width: 835px) {
  .js-mv-scrolldown:after {
    animation: pathmove 1.4s ease-in-out infinite;
  }
}
@media screen and (max-width: 834px) {
  .js-mv-scrolldown:after {
    width: 2px;
    animation: pathmovesp 1.4s ease-in-out infinite;
  }
}

@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 150px;
    opacity: 1;
    @media screen and (max-width: 834px) {
      height: 45px;
    }
  }
  100% {
    height: 0;
    top: 215px;
    opacity: 0;
  }
}
@keyframes pathmovesp {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 45px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 85px;
    opacity: 0;
  }
}
.sec-gallery {
  padding: 89px 0;
}
@media screen and (max-width: 834px) {
  .sec-gallery {
    padding: 36px 0;
  }
}

.p-gallery-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -2px;
}
@media screen and (max-width: 834px) {
  .p-gallery-list {
    margin: 0 -18px 38px;
  }
}
.p-gallery-list__item {
  width: 33.333%;
  padding: 0 2px;
  margin-bottom: 42px;
}
@media screen and (max-width: 834px) {
  .p-gallery-list__item {
    width: 50%;
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  .p-gallery-list__item {
    width: 100%;
  }
}
@media screen and (max-width: 640px) and (max-width: 834px) {
  .p-gallery-list__item {
    margin-bottom: 18px;
  }
  .p-gallery-list__item:last-child {
    margin-bottom: 0;
  }
}
.p-gallery-list__item a {
  display: block;
  color: #141414;
}
.p-gallery-list__item:hover .p-gallery-list__img img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
.p-gallery-list__img {
  position: relative;
  overflow: hidden;
}
.p-gallery-list__img img {
  width: 100%;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.p-gallery-list__img-new {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  background: rgba(221, 221, 221, 0.85);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 9px 16px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.p-gallery-list__ct {
  font-size: 1.6rem;
  line-height: 1.3125;
  font-weight: 500;
  padding: 16px 15px;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 834px) {
  .p-gallery-list__ct {
    padding: 7px 16px;
  }
}
.p-gallery-list__ct-ttl01 {
  display: block;
  padding: 0 100px 0 0;
  position: relative;
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .p-gallery-list__ct-ttl01 {
    padding: 0 84px 0 0;
    margin-bottom: 9px;
  }
}
.p-gallery-list__ct-ttl01 span {
  font-weight: 500;
  position: absolute;
  top: 1px;
  right: 4px;
}
.p-gallery-list__ct-ttl02 {
  display: block;
  color: #919191;
  font-weight: 300;
}
@media screen and (max-width: 834px) {
  .p-gallery-list__ct-ttl02 {
    font-size: 1.2rem;
  }
}

.js-gallery-list--more .p-gallery-list__item {
  display: none;
}

.p-gallery-heading {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 55px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .p-gallery-heading {
    left: auto;
    margin-bottom: 21px;
  }
}
.p-gallery-heading > li {
  margin: 0 80px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .p-gallery-heading > li {
    margin: 0 38px;
  }
}
.p-gallery-heading > li > a {
  display: block;
  font-size: 2.4rem;
  font-weight: 500;
  color: #141414;
  border-bottom: 3px solid;
  padding: 0 0 7px;
  letter-spacing: 2px;
}
@media screen and (max-width: 834px) {
  .p-gallery-heading > li > a {
    padding: 0 0 3px;
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 835px) {
  .p-gallery-heading > li:hover .p-gallery-heading__sub {
    display: block;
  }
}
.p-gallery-heading__sub {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  z-index: 1;
  display: none;
  border: 1px solid #E8E8E8;
}
@media screen and (max-width: 834px) {
  .p-gallery-heading__sub {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .p-gallery-heading__sub.active {
    display: block;
  }
}
.p-gallery-heading__sub > li {
  position: relative;
  border-top: none;
  background: white;
}
.p-gallery-heading__sub > li > a {
  display: block;
  padding: 7px 15px;
  font-weight: 300;
  font-size: 1.6rem;
  color: #141414;
}
.p-gallery-heading__sub > li > a:hover, .p-gallery-heading__sub > li > a.active {
  color: #919191;
}
.p-gallery-heading__sub > li:hover > a, .p-gallery-heading__sub > li.active > a {
  color: #919191;
}
.p-gallery-heading__sub > li:hover ul, .p-gallery-heading__sub > li.active ul {
  display: block;
}
.p-gallery-heading__sub > li ul {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 200px;
  z-index: 1;
  display: none;
  border: 1px solid #E8E8E8;
}
@media screen and (max-width: 834px) {
  .p-gallery-heading__sub > li ul {
    display: block;
    width: 100%;
    border: none;
    position: static;
  }
}
.p-gallery-heading__sub > li ul li {
  background: white;
}
.p-gallery-heading__sub > li ul li a {
  display: block;
  padding: 7px 15px;
  font-weight: 300;
  font-size: 1.6rem;
  color: #141414;
}
.p-gallery-heading__sub > li ul li a:hover, .p-gallery-heading__sub > li ul li a.active {
  color: #919191;
}
@media screen and (max-width: 834px) {
  .p-gallery-heading__sub > li ul li a {
    font-size: 1.4rem;
    padding: 5px 30px;
  }
}

.sec-news {
  padding: 48px 0 160px;
}
@media screen and (max-width: 834px) {
  .sec-news {
    padding: 48px 0 55px;
  }
}

.l-news-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 151px;
}
@media screen and (max-width: 834px) {
  .l-news-block {
    margin-bottom: 78px;
  }
}
.l-news-block .c-ttl__01 {
  width: 34.5%;
  text-align: right;
  padding: 35px 96px 0 0;
}
@media screen and (max-width: 834px) {
  .l-news-block .c-ttl__01 {
    width: 100%;
    padding: 0;
    text-align: left;
  }
}
.l-news-block .p-news-block__list {
  width: 65.5%;
}
@media screen and (max-width: 834px) {
  .l-news-block .p-news-block__list {
    width: 100%;
  }
}

.p-news-block__list-item {
  margin-bottom: 94px;
}
@media screen and (max-width: 834px) {
  .p-news-block__list-item {
    margin-bottom: 39px;
  }
}
.p-news-block__list-item:last-child {
  margin-bottom: 0;
}
.p-news-block__list-item a, .p-news-block__list-item > div {
  display: block;
  color: #141414;
  font-size: 1.6rem;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 834px) {
  .p-news-block__list-item a, .p-news-block__list-item > div {
    font-size: 1.4rem;
  }
  .p-news-block__list-item a p, .p-news-block__list-item > div p {
    line-height: 1.7142857143;
  }
}
.p-news-block__list-item a[href^="#"]:hover, .p-news-block__list-item > div[href^="#"]:hover {
  opacity: 1;
  cursor: auto;
  pointer-events: none;
}
.p-news-block__list-date, .p-news-block__list-category {
  color: #919191;
  font-weight: 500;
  display: inline-block;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .p-news-block__list-date, .p-news-block__list-category {
    font-size: 1.2rem;
    margin-bottom: 4px;
  }
}
.p-news-block__list-date {
  margin-right: 81px;
}
.p-news-block__list-ttl {
  display: block;
  font-size: 2rem;
  border-bottom: 1px solid #141414;
  padding: 0 0 8px;
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .p-news-block__list-ttl {
    font-size: 1.6rem;
    margin-bottom: 4px;
  }
}
.p-news-block__list p {
  font-weight: 300;
}

.js-news-block__list--more .p-news-block__list-item {
  display: none;
}

.c-news-more {
  text-align: right;
}
@media screen and (max-width: 834px) {
  .c-news-more {
    text-align: center;
  }
}

.sec-news-page {
  padding: 89px 0 164px;
}
@media screen and (max-width: 834px) {
  .sec-news-page {
    padding: 38px 0 54px;
  }
}
.sec-news-page .l-inner {
  max-width: 1004px;
}
.sec-news-page .p-news-block__list {
  margin-bottom: 142px;
}
@media screen and (max-width: 834px) {
  .sec-news-page .p-news-block__list {
    margin-bottom: 33px;
  }
}

.sec-gallery-page {
  padding: 90px 0 164px;
}
@media screen and (max-width: 834px) {
  .sec-gallery-page {
    padding: 100px 0 55px;
  }
}

.p-staff {
  padding: 144px 0 146px;
}
@media screen and (max-width: 834px) {
  .p-staff {
    padding: 37px 0 74px;
  }
}
.p-staff__img img {
  display: block;
  width: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
@media screen and (max-width: 640px) {
  .p-staff__img img {
    height: 315px;
  }
}
.p-staff__top {
  border-bottom: 1px solid #141414;
  padding: 89px 0 72px;
  margin-bottom: 69px;
}
@media screen and (max-width: 834px) {
  .p-staff__top {
    padding: 41px 0 37px;
    margin-bottom: 37px;
  }
}
@media screen and (min-width: 641px) {
  .p-staff__top-flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 640px) {
  .p-staff__top-flex {
    text-align: center;
  }
}
.p-staff__top-left {
  letter-spacing: 0.09em;
}
@media screen and (min-width: 641px) {
  .p-staff__top-left {
    width: calc(50.5% - 15px);
    max-width: 451px;
    text-align: right;
    padding: 8px 0 0;
  }
}
@media screen and (max-width: 640px) {
  .p-staff__top-left {
    margin-bottom: 13px;
  }
}
.p-staff__top-right {
  letter-spacing: 0.075em;
}
@media screen and (min-width: 641px) {
  .p-staff__top-right {
    width: 49.5%;
  }
}
@media screen and (max-width: 640px) {
  .p-staff__top-right {
    font-size: 1.2rem;
    line-height: 2;
  }
}
.p-staff__top-right > * {
  display: block;
}
.p-staff__top-right .txt-lg {
  font-size: 2.4rem;
  line-height: 1.1666666667;
  letter-spacing: 0.12em;
  margin-bottom: 12px;
}
@media screen and (max-width: 834px) {
  .p-staff__top-right .txt-lg {
    font-size: 2.2rem;
    line-height: 1.2727272727;
    margin-bottom: 2px;
  }
}
.p-staff__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  letter-spacing: 0.1em;
  padding: 0 55px;
}
@media screen and (max-width: 834px) {
  .p-staff__list {
    justify-content: space-between;
    padding: 0 40px;
  }
}
@media screen and (max-width: 374px) {
  .p-staff__list {
    padding: 0 20px;
  }
}
.p-staff__list > * {
  width: 33.33%;
  margin-bottom: 36px;
}
@media screen and (max-width: 640px) {
  .p-staff__list > * {
    width: 50%;
    margin-bottom: 20px;
    text-align: left;
  }
  .p-staff__list > *:nth-child(2n) {
    padding-left: 35px;
  }
}
@media screen and (max-width: 374px) {
  .p-staff__list > *:nth-child(2n) {
    padding-left: 20px;
  }
}

.p-about {
  padding: 89px 0 160px;
}
@media screen and (max-width: 834px) {
  .p-about {
    padding: 37px 0 56px;
  }
}
.p-about__info {
  padding: 36px 0 22px;
  border-bottom: 1px solid #141414;
  margin-bottom: 119px;
}
@media screen and (max-width: 834px) {
  .p-about__info {
    padding: 9px 0 22px;
    margin-bottom: 79px;
  }
}
.p-about__info-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.p-about__info-col {
  width: 50%;
  padding: 0 15px 0 0;
  letter-spacing: 1.1px;
}
@media screen and (max-width: 834px) {
  .p-about__info-col {
    letter-spacing: 0;
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
  .p-about__info-col:last-child {
    margin-bottom: 0;
  }
}
.p-about__info-col .c-txt__02 {
  margin-bottom: 7px;
}
@media screen and (max-width: 834px) {
  .p-about__info-col .c-txt__02 {
    margin-bottom: 3px;
  }
}
.p-about__info-col .c-txt__02:last-child {
  margin-bottom: 0;
}
.p-about__info-col .c-txt__01 {
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .p-about__info-col .c-txt__01 {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    margin-bottom: 15px;
  }
}
.p-about__info-col .c-txt__01:last-child {
  margin-bottom: 0;
}
.p-about__info-col .c-txt__01--other {
  letter-spacing: 0.3px;
}
.p-about__info-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.p-about__info-row span {
  min-width: 112px;
}
@media screen and (max-width: 834px) {
  .p-about__info-row span {
    min-width: 98px;
  }
}
.p-about__list {
  margin-bottom: 121px;
}
@media screen and (max-width: 834px) {
  .p-about__list {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    margin-bottom: 80px;
  }
}
.p-about__list > * {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px;
}
@media screen and (max-width: 834px) {
  .p-about__list > * {
    margin-bottom: 48px;
  }
}
.p-about__list > *:last-child {
  margin-bottom: 0;
}
.p-about__list-img {
  width: 37.59%;
}
@media screen and (max-width: 834px) {
  .p-about__list-img {
    width: 45.49%;
  }
}
.p-about__list-ct {
  width: 62.41%;
  padding: 41px 0 0 15px;
  max-width: 513px;
  margin-left: auto;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 834px) {
  .p-about__list-ct {
    width: 54.51%;
    padding: 7px 0 0 16px;
  }
}
.p-about__list-ct a {
  color: inherit;
}
.p-about__list-ct a[href^=tel] {
  margin-left: 5px;
}
.p-about__list-ttl {
  font-size: 2rem;
  line-height: 1.4;
  letter-spacing: 0.1em;
  margin-bottom: 21px;
}
@media screen and (max-width: 834px) {
  .p-about__list-ttl {
    font-size: 1.8rem;
    line-height: 1.5555555556;
    margin-bottom: 5px;
  }
}
.p-about__list-map {
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  position: relative;
}
.p-about__list-map::before {
  position: absolute;
  content: "";
  bottom: 1px;
  left: 0;
  right: 0;
  height: 1px;
  background: #141414;
}
.p-about__award {
  margin-bottom: 120px;
}
@media screen and (max-width: 834px) {
  .p-about__award {
    margin-bottom: 79px;
  }
}
.p-about__award-ct {
  letter-spacing: 0.06em;
}
@media screen and (min-width: 835px) {
  .p-about__award-ct {
    line-height: 2;
  }
}
.p-about__award-ct > * {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
@media screen and (max-width: 834px) {
  .p-about__award-ct > * {
    margin-bottom: 28px;
  }
}
.p-about__award-ct dt {
  width: 121px;
  letter-spacing: 0.08em;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .p-about__award-ct dt {
    text-align: left;
    width: 62px;
  }
}
.p-about__award-ct dd {
  width: calc(100% - 121px);
  font-weight: 300;
}
@media screen and (max-width: 834px) {
  .p-about__award-ct dd {
    width: calc(100% - 62px);
  }
}
.p-about__award-ct a {
  color: inherit;
  text-decoration: underline;
  text-underline-position: unset;
}
.p-about__initiatives-ct {
  padding: 37px 41px 36px;
  font-weight: 300;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 834px) {
  .p-about__initiatives-ct {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    padding: 22px 0;
  }
}
.p-about__initiatives-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.p-about__initiatives-list > * {
  width: 16.6666666667%;
}
@media screen and (max-width: 640px) {
  .p-about__initiatives-list > * {
    width: 33.33%;
  }
}

.p-flow {
  padding: 89px 0 160px;
}
@media screen and (max-width: 834px) {
  .p-flow {
    padding: 37px 0 54px;
  }
}
.p-flow__inner {
  max-width: 1058px;
}
.p-flow__des {
  margin-bottom: 94px;
  line-height: 1.5;
}
@media screen and (min-width: 641px) {
  .p-flow__des {
    text-align: center;
  }
}
@media screen and (max-width: 834px) {
  .p-flow__des {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    margin-bottom: 32px;
  }
}
.p-flow__list {
  padding: 0 31px;
  counter-reset: counterNumber;
}
@media screen and (max-width: 834px) {
  .p-flow__list {
    padding: 0;
  }
}
.p-flow__list > * {
  padding: 0 0 83px 168px;
  position: relative;
  counter-increment: counterNumber;
}
@media screen and (max-width: 834px) {
  .p-flow__list > * {
    padding: 0 0 50px 100px;
  }
}
@media screen and (max-width: 640px) {
  .p-flow__list > * {
    padding: 0 0 41px 56px;
  }
}
.p-flow__list > *:last-child {
  padding-bottom: 0;
}
.p-flow__list > *:last-child::before {
  display: none;
}
.p-flow__list > *::before, .p-flow__list > *::after {
  position: absolute;
  content: "";
}
.p-flow__list > *::before {
  top: 25px;
  bottom: -25px;
  left: 9px;
  border-left: 1px dashed #141414;
}
@media screen and (max-width: 834px) {
  .p-flow__list > *::before {
    left: 21px;
    top: 22px;
    bottom: -22px;
  }
}
.p-flow__list > *::after {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  left: 5px;
  top: 16px;
}
@media screen and (max-width: 834px) {
  .p-flow__list > *::after {
    left: 17px;
    top: 12px;
  }
}
.p-flow__list > *.has-dot::after {
  background: #141414;
}
.p-flow__list-ttl {
  margin-bottom: 41px;
}
@media screen and (max-width: 834px) {
  .p-flow__list-ttl {
    margin-bottom: 18px;
  }
}
.p-flow__list-number {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 5rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #191919;
}
@media screen and (min-width: 835px) {
  .p-flow__list-number {
    position: absolute;
    top: -10px;
    left: 61px;
  }
}
@media screen and (max-width: 1245px) {
  .p-flow__list-number {
    font-size: 4.1rem;
  }
}
@media screen and (max-width: 834px) {
  .p-flow__list-number {
    font-size: 3.2rem;
    line-height: 1.1875;
    display: block;
    margin-bottom: 3px;
  }
}
.p-flow__list-number::before {
  content: "0" counter(counterNumber);
}
.p-flow__list-note {
  position: absolute;
  top: 5px;
  right: 0;
  background: #141414;
  color: white;
  line-height: 1;
  font-size: 1.6rem;
  padding: 7px 14px 8px 11px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 834px) {
  .p-flow__list-note {
    font-size: 1.2rem;
    line-height: 1.3333333333;
    top: 38px;
  }
}
@media screen and (max-width: 640px) {
  .p-flow__list-note {
    top: 26px;
    padding: 4px 5px 4px 5px;
    letter-spacing: 0.01em;
  }
}
.p-flow__list-circle {
  position: absolute;
  left: -31px;
  top: 89px;
  width: 82px;
  height: 82px;
  border: 1px solid #707070;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.2857142857;
  letter-spacing: 0.25em;
  background: white;
}
@media screen and (max-width: 834px) {
  .p-flow__list-circle {
    width: 59px;
    height: 59px;
    left: -8px;
    top: 77px;
    padding: 0 0 3px;
    font-size: 1.3rem;
  }
}
.p-flow__list-circle small {
  font-size: 1.2rem;
}
@media screen and (max-width: 834px) {
  .p-flow__list-circle small {
    display: block;
  }
}
.p-flow__list-circle .number {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .p-flow__list-circle .number {
    font-size: 1.4rem;
  }
}
.p-flow__list-circle .number--block {
  display: block;
}
.p-flow__list .c-txt__01 {
  line-height: 1.5;
  letter-spacing: 0.075em;
}
@media screen and (max-width: 834px) {
  .p-flow__list .c-txt__01 {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    letter-spacing: 0.08em;
  }
}

.l-page-mv--gallery {
  position: relative;
}
.l-page-mv--gallery img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.l-page-mv--gallery .scroll-dow {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  background: rgba(20, 20, 20, 0.7);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12.4%;
}
.l-page-mv--gallery .scroll-dow:after {
  content: "";
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  position: absolute;
  bottom: 11px;
}

.sec-gallery-detail {
  padding: 39px 0 157px;
}
@media screen and (max-width: 834px) {
  .sec-gallery-detail {
    padding: 11px 0 54px;
  }
}
.sec-gallery-detail .l-inner {
  position: relative;
  max-width: 1036px;
}
.sec-gallery-detail .c-ttl__03 {
  color: #191919;
  border-bottom: none;
  padding: 0;
  letter-spacing: 4px;
}
.sec-gallery-detail .c-btn__02 {
  width: 163px;
  letter-spacing: 2.5px;
}

.sticky {
  position: sticky;
  top: 0;
  right: 0;
  height: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  z-index: 10;
}
@media screen and (max-width: 834px) {
  .sticky {
    padding-top: 60px;
  }
}

.gallery-award {
  display: inline-block;
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
  color: #707070;
  letter-spacing: 1px;
  z-index: 2;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0 0 23px;
}
@media screen and (max-width: 834px) {
  .gallery-award.fixed {
    position: fixed;
    top: 80px;
    right: 15px;
  }
  .gallery-award.fixed:after {
    top: calc(100% - 12px);
    height: 28px;
  }
}
@media screen and (min-width: 835px) {
  .gallery-award:hover {
    opacity: 0.7;
  }
}
.gallery-award:after {
  content: "";
  width: 1px;
  background: #707070;
  height: 43px;
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media screen and (max-width: 834px) {
  .gallery-award:after {
    top: calc(100% + 12px);
    height: 28px;
  }
}
.gallery-award:before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  border: 1px solid #707070;
  z-index: 1;
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: white;
}
@media screen and (min-width: 835px) {
  .gallery-award:before {
    animation: circlemove 1.6s ease-in-out infinite, cirlemovehide 1.6s ease-out infinite;
  }
}
@media screen and (max-width: 834px) {
  .gallery-award:before {
    top: calc(100% + 2px);
    animation: circlemovesp 1.6s ease-in-out infinite, cirlemovehide 1.6s ease-out infinite;
  }
}
.gallery-award.off:before {
  display: none;
}

@keyframes circlemove {
  0% {
    top: 95%;
  }
  100% {
    top: calc(100% + 50px);
  }
}
@keyframes circlemovesp {
  0% {
    top: 30px;
  }
  100% {
    top: calc(100% + 37px);
  }
}
@keyframes cirlemovehide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
.l-gallery-article {
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 300;
  margin-bottom: 160px;
}
@media screen and (max-width: 834px) {
  .l-gallery-article {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    margin-bottom: 62px;
  }
}
.l-gallery-article__category {
  display: block;
  color: #919191;
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .l-gallery-article__category {
    margin-bottom: 0;
  }
}
.l-gallery-article__date {
  display: block;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .l-gallery-article__date {
    margin-bottom: 0;
  }
}
.l-gallery-article__block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.l-gallery-article__block-ct {
  width: 39%;
  padding: 0 6% 0 0;
  letter-spacing: 0.9px;
  position: relative;
  top: -3px;
  text-align: justify;
}
@media screen and (max-width: 834px) {
  .l-gallery-article__block-ct {
    top: auto;
    width: 100%;
    padding: 0;
    margin-bottom: 70px;
  }
}
.l-gallery-article__block-img {
  width: 61%;
}
@media screen and (max-width: 834px) {
  .l-gallery-article__block-img {
    width: auto;
    margin: 0 -18px;
  }
}
.l-gallery-article__block-img li {
  overflow: hidden;
}
.l-gallery-article__block-img li a {
  display: block;
  overflow: hidden;
}
.l-gallery-article__block-img li a img {
  width: 100%;
  margin-bottom: 2px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.l-gallery-article__block-img li a:hover {
  opacity: 1;
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
.l-gallery-article h1, .l-gallery-article h2, .l-gallery-article h3, .l-gallery-article h4, .l-gallery-article h5 {
  display: block;
  font-weight: 400;
  margin-bottom: 30px;
}
.l-gallery-article h1 {
  font-size: 3.2rem;
  letter-spacing: 4.5px;
  margin-bottom: 156px;
}
@media screen and (max-width: 834px) {
  .l-gallery-article h1 {
    font-size: 2rem;
    letter-spacing: 2.8px;
    margin-bottom: 73px;
  }
}
.l-gallery-article h2 {
  font-size: 2.6rem;
}
@media screen and (max-width: 834px) {
  .l-gallery-article h2 {
    font-size: 1.8rem;
  }
}
.l-gallery-article h3 {
  font-size: 2rem;
}
@media screen and (max-width: 834px) {
  .l-gallery-article h3 {
    font-size: 1.6rem;
  }
}
.l-gallery-article h4 {
  font-size: 1.8rem;
}
@media screen and (max-width: 834px) {
  .l-gallery-article h4 {
    font-size: 1.4rem;
  }
}
.l-gallery-article p {
  margin-bottom: 29px;
}
@media screen and (max-width: 834px) {
  .l-gallery-article p {
    margin-bottom: 23px;
  }
}
.l-gallery-article p:last-child {
  margin-bottom: 0;
}
.l-gallery-article__award {
  padding: 36px 0 0;
  font-weight: 300;
}
@media screen and (max-width: 834px) {
  .l-gallery-article__award {
    padding: 57px 0 0;
  }
}
.l-gallery-article__award-ttl {
  display: block;
  font-size: 3.2rem;
  font-weight: 500;
  border-bottom: 1px solid;
  letter-spacing: 3px;
  line-height: 1.7;
  margin-bottom: 30px;
  color: #191919;
}
@media screen and (max-width: 834px) {
  .l-gallery-article__award-ttl {
    font-size: 2.4rem;
    border-bottom: 1px solid;
    letter-spacing: 2px;
    line-height: 1.7;
    margin-bottom: 10px;
  }
}
.l-gallery-article__award table {
  width: 100%;
}
.l-gallery-article__award table tr th {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
.p-project-other {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 38px;
}
@media screen and (max-width: 834px) {
  .p-project-other {
    margin: 0 -18px 42px;
  }
}
.p-project-other__item {
  width: 33.333%;
  position: relative;
}
@media screen and (max-width: 834px) {
  .p-project-other__item {
    width: 100%;
  }
}
.p-project-other__item img {
  width: 100%;
}
.p-project-other__item a {
  display: block;
  color: white;
}
.p-project-other__item a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.53);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 834px) {
  .p-project-other__item a:before {
    display: none;
  }
}
.p-project-other__item a:hover {
  opacity: 1;
}
.p-project-other__item a:hover:before {
  opacity: 1;
  visibility: visible;
}
.p-project-other__item a:hover .p-project-other__ttl {
  opacity: 1;
  visibility: visible;
}
.p-project-other__ttl {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  padding: 0 5px;
  text-align: center;
  font-size: 1.8rem;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 834px) {
  .p-project-other__ttl {
    transform: translateY(0);
    font-size: 1.6rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    opacity: 1;
    visibility: visible;
    top: auto;
    left: 18px;
    right: 18px;
    padding: 0;
    bottom: 8px;
    text-align: left;
  }
}
.p-project-other__ttl span {
  display: block;
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .p-project-other__ttl span {
    width: 100%;
    order: 2;
  }
}
.p-project-other__ttl span:last-child {
  margin-bottom: 0;
}
.p-project-other__ttl .c-ft--en {
  font-size: 1.2rem;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .p-project-other__ttl .c-ft--en {
    font-size: 1.4rem;
    order: 1;
  }
}

@media screen and (max-width: 834px) {
  #colorbox {
    width: 100% !important;
    height: 100vh !important;
    top: 0 !important;
  }
}

@media screen and (max-width: 834px) {
  #cboxWrapper {
    width: 100% !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 834px) {
  #cboxLoadedContent {
    width: 100% !important;
    height: auto !important;
  }
}
#cboxLoadedContent img {
  width: auto !important;
  max-height: 80vh;
  max-width: 100% !important;
}
@media screen and (max-width: 834px) {
  #cboxLoadedContent img {
    width: 100% !important;
    height: 67vw !important;
    object-fit: cover;
    font-family: "object-fit: cover";
  }
}

#cboxContent {
  padding: 60px 74px 50px;
  font-size: 1.4rem;
  color: black;
}
@media screen and (max-width: 834px) {
  #cboxContent {
    width: 100% !important;
    padding: 100px 0;
    height: auto !important;
  }
}

@media screen and (max-width: 834px) {
  #cboxCurrent {
    position: absolute;
    bottom: 70px;
    font-size: 12px;
    left: 18px;
  }
}

#cboxNext, #cboxPrevious {
  width: 26px;
  height: 26px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  #cboxNext, #cboxPrevious {
    width: 40px;
    height: 40px;
  }
}

#cboxNext {
  background: url(../img/common/next.png) no-repeat;
  background-size: 100%;
  left: auto;
  right: 27px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  #cboxNext {
    right: 0;
  }
}
#cboxNext:hover {
  background: url("../img/common/next.png") no-repeat;
  background-size: 100%;
  opacity: 0.7;
}

#cboxPrevious {
  background: url("../img/common/prev.png") no-repeat;
  background-size: 100%;
  left: 27px;
}
@media screen and (max-width: 834px) {
  #cboxPrevious {
    left: 0;
  }
}
#cboxPrevious:hover {
  background: url("../img/common/prev.png") no-repeat;
  background-size: 100%;
  opacity: 0.7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#cboxClose {
  width: 54px;
  height: 41px;
  background: url("../img/common/close.png") no-repeat;
  background-size: 100%;
  top: 19px;
  right: 74px;
  left: auto;
  bottom: auto;
}
@media screen and (max-width: 834px) {
  #cboxClose {
    top: 0;
    right: 0;
  }
}
#cboxClose:hover {
  background: url("../img/common/close.png") no-repeat;
  background-size: 100%;
  opacity: 0.7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.p-policy {
  padding: 159px 0 339px;
}
@media screen and (max-width: 1245px) {
  .p-policy {
    padding: 100px 0 200px;
  }
}
@media screen and (max-width: 834px) {
  .p-policy {
    padding: 42px 0 88px;
  }
}
.p-policy__flex {
  max-width: 1236px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) and (min-width: 375px) {
  .p-policy__flex {
    padding: 0 36px;
  }
}
.p-policy__ct {
  width: 52.75%;
  max-width: 530px;
  padding: 24px 48px 0;
}
@media screen and (max-width: 834px) {
  .p-policy__ct {
    padding: 0 15px 0 0;
  }
}
@media screen and (max-width: 640px) {
  .p-policy__ct {
    width: 100%;
    padding: 0;
    margin-bottom: 38px;
  }
}
@media screen and (min-width: 835px) {
  .p-policy__ct .c-txt__01 {
    letter-spacing: 0.15em;
  }
}
@media screen and (max-width: 834px) {
  .p-policy__ct .c-txt__01 {
    font-size: 1.4rem;
    line-height: 1.7142857143;
  }
}
.p-policy__img {
  width: 47.25%;
}
@media screen and (max-width: 640px) {
  .p-policy__img {
    width: 100%;
  }
}

.p-concept {
  padding: 0 0 160px;
}
@media screen and (max-width: 1245px) {
  .p-concept {
    padding: 0 0 100px;
  }
}
@media screen and (max-width: 834px) {
  .p-concept {
    padding: 0 0 56px;
  }
}
.p-concept-list {
  margin-bottom: 192px;
}
@media screen and (max-width: 1245px) {
  .p-concept-list {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 834px) {
  .p-concept-list {
    margin-bottom: 56px;
  }
}
.p-concept-list > *:first-child {
  padding-bottom: 260px;
}
@media screen and (max-width: 1245px) {
  .p-concept-list > *:first-child {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:first-child {
    position: relative;
    z-index: 1;
    padding: 56px 0 87px;
  }
  .p-concept-list > *:first-child::before {
    position: absolute;
    z-index: -1;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    background: #F8F8F8;
    top: 0;
    bottom: 0;
    width: 100vw;
  }
}
.p-concept-list > *:first-child .p-concept-list__flex {
  max-width: 1140px;
}
.p-concept-list > *:first-child .p-concept-list__img {
  width: 39.75%;
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:first-child .p-concept-list__img {
    width: calc(68.65% + 36px);
    margin-left: -36px;
  }
}
@media screen and (max-width: 374px) {
  .p-concept-list > *:first-child .p-concept-list__img {
    width: calc(67.25% + 18px);
    margin-left: -18px;
  }
}
.p-concept-list > *:first-child .p-concept-list__ct {
  width: calc(60.25% - 15px);
  max-width: 499px;
  padding: 96px 0 62px;
  margin-right: 3px;
}
@media screen and (max-width: 1245px) {
  .p-concept-list > *:first-child .p-concept-list__ct {
    padding: 30px 0;
  }
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:first-child .p-concept-list__ct {
    width: 100%;
    max-width: 100%;
    padding: 42px 0 30px;
  }
}
.p-concept-list > *:nth-child(2) {
  padding-bottom: 180px;
}
@media screen and (max-width: 1245px) {
  .p-concept-list > *:nth-child(2) {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:nth-child(2) {
    position: relative;
    z-index: 1;
    padding-bottom: 53px;
  }
  .p-concept-list > *:nth-child(2)::before {
    position: absolute;
    z-index: -1;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    background: #F8F8F8;
    top: 0;
    bottom: 0;
    width: 100vw;
  }
}
.p-concept-list > *:nth-child(2) .p-concept-list__flex {
  flex-direction: row-reverse;
}
.p-concept-list > *:nth-child(2) .p-concept-list__img {
  width: 47.45%;
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:nth-child(2) .p-concept-list__img {
    width: 100vw;
    margin-left: -36px;
    margin-right: -33px;
  }
}
@media screen and (max-width: 374px) {
  .p-concept-list > *:nth-child(2) .p-concept-list__img {
    margin-left: -18px;
    margin-right: -18px;
  }
}
.p-concept-list > *:nth-child(2) .p-concept-list__ct {
  width: calc(52.55% - 70px);
  margin-right: 0;
  padding: 30px 0 63px;
  margin-left: 55px;
  max-width: 506px;
}
@media screen and (max-width: 1024px) {
  .p-concept-list > *:nth-child(2) .p-concept-list__ct {
    width: calc(52.55% - 15px);
    margin-left: 0;
  }
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:nth-child(2) .p-concept-list__ct {
    width: 100%;
    padding: 42px 0 40px;
  }
  .p-concept-list > *:nth-child(2) .p-concept-list__ct .c-txt__01 {
    letter-spacing: 0.11em;
  }
}
.p-concept-list > *:nth-child(2) .p-concept-list__number {
  margin-bottom: 45px;
}
@media screen and (max-width: 834px) {
  .p-concept-list > *:nth-child(2) .p-concept-list__number {
    margin-bottom: 28px;
  }
}
.p-concept-list > *:nth-child(3) {
  background: url(../img/concept/img-04.jpg) center/cover;
  color: white;
  margin-bottom: 192px;
}
@media screen and (max-width: 834px) {
  .p-concept-list > *:nth-child(3) {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:nth-child(3) {
    margin-bottom: 66px;
  }
}
.p-concept-list > *:nth-child(3) .p-concept-list__flex {
  display: block;
  max-width: 1140px;
  padding-top: 137px;
  padding-bottom: 134px;
}
@media screen and (max-width: 834px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__flex {
    padding-top: 65px;
    padding-bottom: 64px;
  }
}
.p-concept-list > *:nth-child(3) .p-concept-list__ct {
  position: relative;
  padding: 0 0 225px 0;
}
@media screen and (max-width: 834px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__ct {
    padding: 0 0 40px;
  }
}
@media screen and (min-width: 835px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__ct-img {
    position: absolute;
    right: 66px;
    top: 53.1%;
    transform: translateY(-50%);
    width: 29.7101449275%;
  }
}
@media screen and (max-width: 834px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__ct-img {
    text-align: center;
    padding: 5px 3px 0 0;
  }
}
@media screen and (max-width: 374px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__ct-img {
    padding: 0;
  }
}
@media screen and (min-width: 835px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__ct .c-ttl__05,
.p-concept-list > *:nth-child(3) .p-concept-list__ct .c-txt__01 {
    width: 50%;
  }
}
.p-concept-list > *:nth-child(3) .p-concept-list__ct .c-ttl__05 {
  padding: 0 0 0 5px;
}
.p-concept-list > *:nth-child(3) .p-concept-list__number {
  margin-bottom: 140px;
}
@media screen and (max-width: 1245px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__number {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 834px) {
  .p-concept-list > *:nth-child(3) .p-concept-list__number {
    margin-bottom: 29px;
  }
}
.p-concept-list > *:nth-child(4) .p-concept-list__flex {
  flex-direction: row-reverse;
}
.p-concept-list > *:nth-child(4) .p-concept-list__ct {
  width: calc(53.6% - 63px);
  max-width: 452px;
  padding: 17px 0 62px;
  margin-right: 48px;
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:nth-child(4) .p-concept-list__ct {
    width: 100%;
    max-width: 100%;
    margin: 0 0 72px;
    padding: 0 0 33px;
  }
}
.p-concept-list > *:nth-child(4) .p-concept-list__ct .c-txt__03 {
  margin-bottom: 14px;
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:nth-child(4) .p-concept-list__ct .c-txt__03 {
    margin-bottom: 21px;
  }
}
.p-concept-list > *:nth-child(4) .p-concept-list__ct .c-txt__03:last-child {
  margin-bottom: 0;
}
.p-concept-list > *:nth-child(4) .p-concept-list__img {
  width: 46.4%;
}
@media screen and (max-width: 640px) {
  .p-concept-list > *:nth-child(4) .p-concept-list__img {
    width: 100vw;
    margin-left: -36px;
    margin-right: -33px;
  }
}
@media screen and (max-width: 374px) {
  .p-concept-list > *:nth-child(4) .p-concept-list__img {
    margin-left: -18px;
    margin-right: -18px;
  }
}
.p-concept-list__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1236px;
}
@media screen and (max-width: 640px) and (min-width: 375px) {
  .p-concept-list__flex {
    padding: 0 33px 0 36px;
  }
}
.p-concept-list__ct {
  border-bottom: 1px solid #C4C4C4;
}
.p-concept-list__number {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: 500;
  border-bottom: 1px solid #C4C4C4;
  color: #919191;
  letter-spacing: 0.07em;
  padding-bottom: 10px;
  margin-bottom: 45px;
}
@media screen and (max-width: 834px) {
  .p-concept-list__number {
    padding-bottom: 5px;
    margin-bottom: 28px;
  }
}
.p-concept-list__text {
  padding: 24px 0 0 16px;
}
@media screen and (max-width: 834px) {
  .p-concept-list__text {
    padding: 21px 4px 0 0;
  }
}
.p-concept-list .c-txt__01, .p-concept-list .c-txt__03 {
  letter-spacing: 0.06em;
}
.p-concept-list .c-txt__01 {
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .p-concept-list .c-txt__01 {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    margin-bottom: 17px;
  }
}
.p-concept-list .c-txt__01:last-child {
  margin-bottom: 0;
}
.p-concept-list .c-txt__03 {
  margin-bottom: 1px;
}
@media screen and (max-width: 834px) {
  .p-concept-list .c-txt__03 {
    margin-bottom: 4px;
  }
}
.p-concept-list .c-txt__03:last-child {
  margin-bottom: 0;
}
.p-concept-slider .item {
  width: 354px;
}
@media screen and (max-width: 834px) {
  .p-concept-slider .item {
    width: 319px;
  }
}
.p-concept-slider .item a {
  display: block;
  position: relative;
  color: white;
}
.p-concept-slider .item a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.53);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 834px) {
  .p-concept-slider .item a:before {
    display: none;
  }
}
.p-concept-slider .item a:hover {
  opacity: 1;
}
.p-concept-slider .item a:hover::before, .p-concept-slider .item a:hover .p-concept-slider__ttl {
  opacity: 1;
  visibility: visible;
}
.p-concept-slider .item img {
  display: block;
  width: 100%;
}
.p-concept-slider__ttl {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  padding: 0 5px;
  text-align: center;
  font-size: 1.8rem;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 834px) {
  .p-concept-slider__ttl {
    transform: translateY(0);
    font-size: 1.6rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    opacity: 1;
    visibility: visible;
    top: auto;
    left: 18px;
    right: 18px;
    padding: 0;
    bottom: 8px;
    text-align: left;
  }
}
.p-concept-slider__ttl span {
  display: block;
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .p-concept-slider__ttl span {
    width: 100%;
    order: 2;
  }
}
.p-concept-slider__ttl span:last-child {
  margin-bottom: 0;
}
.p-concept-slider__ttl .c-ft--en {
  font-size: 1.2rem;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .p-concept-slider__ttl .c-ft--en {
    font-size: 1.4rem;
    order: 1;
  }
}

.sec-contact-page {
  padding: 89px 0 160px;
}
@media screen and (max-width: 834px) {
  .sec-contact-page {
    padding: 38px 0 57px;
  }
}

.contact-note {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 31px;
}
@media screen and (max-width: 834px) {
  .contact-note {
    font-size: 1.4rem;
    line-height: 1.7142857143;
    text-align: left;
    letter-spacing: 1.2px;
  }
}
.contact-note p {
  margin-bottom: 27px;
}
@media screen and (max-width: 834px) {
  .contact-note p {
    margin-bottom: 24px;
  }
}
.contact-note p:last-child {
  margin-bottom: 0;
}
.contact-note a {
  color: #141414;
}

.contact-block {
  width: 100%;
  max-width: 679px;
  margin: 0 auto;
  font-size: 1.6rem;
}
.contact-block__item {
  margin-bottom: 31px;
}
@media screen and (max-width: 834px) {
  .contact-block__item {
    margin-bottom: 23px;
  }
}
@media screen and (min-width: 835px) {
  .contact-block__item.w50 {
    max-width: 50%;
  }
}
.contact-block__item dt {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 8px;
  letter-spacing: 1px;
}
.contact-block__item dt .note {
  display: block;
  line-height: 1;
  font-size: 1.2rem;
  background: #141414;
  color: white;
  font-weight: 500;
  margin: 0 0 0 9px;
  padding: 2px 5px 3px;
  position: relative;
  top: 2px;
}
@media screen and (max-width: 834px) {
  .contact-block__item dt .note {
    margin: 0 0 0 6px;
    top: 1px;
  }
}
.contact-block__item dd {
  font-weight: 300;
}
.contact-block__note {
  display: block;
  font-size: 1.4rem;
  font-weight: 300;
  color: #919191;
  padding: 8px 0 0;
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  .contact-block__note {
    padding: 6px 0 0;
    margin-bottom: 6px;
  }
}
.contact-block__code {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.contact-block__code-number {
  width: 312px;
  margin-right: 10px;
}
@media screen and (max-width: 834px) {
  .contact-block__code-number {
    width: 55%;
  }
}
.contact-block__code-sub {
  width: calc(45% - 10px);
}
.contact-block__code-sub .code-sub {
  font-size: 1.6rem;
  font-weight: 300;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 136px;
  height: 100%;
  border: 1px solid #919191;
  outline: none;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: white;
  color: #141414;
}
.contact-block__code-sub .code-sub:hover {
  background: #919191;
  opacity: 1;
  color: white;
}
.contact-block__check {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.contact-block__sub {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.contact-block__sub .c-btn__03 {
  margin: 0 7px 15px;
}
@media screen and (max-width: 834px) {
  .contact-block__sub .c-btn__03 {
    margin-bottom: 6px;
  }
}
.contact-block__sub-note {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1.7px;
  color: #919191;
}