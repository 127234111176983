@use "vars" as *;

.p-staff {
    padding: 144px 0 146px;
    @include mq {
        padding: 37px 0 74px;
    }
    &__img {
        img {
            display: block;
            width: 100%;
            @include ob;
            @include mq(sm) {
                height: 315px;
            }
        }
    }
    &__top {
        border-bottom: 1px solid #141414;
        padding: 89px 0 72px;
        margin-bottom: 69px;
        @include mq {
            padding: 41px 0 37px;
            margin-bottom: 37px;
        }
        &-flex {
            @include mq(sm-min) {
                @include flex(between, start, wrap);
            }
            @include mq(sm) {
                text-align: center;
            }
        }
        &-left {
            letter-spacing: .09em;
            @include mq(sm-min) {
                width: calc(50.5% - 15px);
                max-width: 451px;
                text-align: right;
                padding: 8px 0 0;
            }
            @include mq(sm) {
                margin-bottom: 13px;
            }
        }
        &-right {
            letter-spacing: .075em;
            @include mq(sm-min) {
                width: 49.5%;
            }
            @include mq(sm) {
                @include fts(12,24);
            }
            > * {
                display: block;
            }
            .txt-lg {
                @include fts(24,28);
                letter-spacing: .12em;
                margin-bottom: 12px;
                @include mq {
                    @include fts(22,28);
                    margin-bottom: 2px;
                }
            }
        }
    }
    &__list {
        @include flex(0,0,wrap);
        letter-spacing: .1em;
        padding: 0 55px;
        @include mq {
            justify-content: space-between;
            padding: 0 40px;
        }
        @include mq(xs){
            padding: 0 20px;
        }
        > * {
            width: 33.33%;
            margin-bottom: 36px;
            @include mq(sm) {
                width: 50%;
                margin-bottom: 20px;
                text-align: left;
                &:nth-child(2n){
                    padding-left: 35px;
                }
            }
            @include mq(xs){
                &:nth-child(2n){
                    padding-left: 20px;
                }
            }
        }
    }
}