@use "vars" as *;

.p-flow {
    padding: 89px 0 160px;
    @include mq {
        padding: 37px 0 54px;
    }
    &__inner {
        max-width: 1058px;
    }
    &__des {
        margin-bottom: 94px;
        line-height: 1.5;
        @include mq(sm-min) {
            text-align: center;
        }
        @include mq {
            @include fts(14,24);
            margin-bottom: 32px;
        }
    }
    &__list {
        padding: 0 31px;
        counter-reset: counterNumber;
        @include mq {
            padding: 0;
        }
        > * {
            padding: 0 0 83px 168px;
            position: relative;
            counter-increment: counterNumber;
            @include mq {
                padding: 0 0 50px 100px;
            }
            @include mq(sm) {
                padding: 0 0 41px 56px;
            }
            &:last-child {
                padding-bottom: 0;
                &::before {
                    display: none;
                }
            }
            &::before, &::after {
                position: absolute;
                content: '';
            }
            &::before {
                top: 25px;
                bottom: -25px;
                left: 9px;
                border-left: 1px dashed #141414;
                @include mq {
                    left: 21px;
                    top: 22px;
                    bottom: -22px;
                }
            }
            &::after {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                left: 5px;
                top: 16px;
                @include mq {
                    left: 17px;
                    top: 12px;
                }
            }
            &.has-dot {
                &::after {
                    background: #141414;
                }
            }
        }
        &-ttl {
            margin-bottom: 41px;
            @include mq {
                margin-bottom: 18px;
            }
        }
        &-number {
            font-family: $font-en;
            font-weight: 500;
            @include fts(50,60);
            letter-spacing: .1em;
            color: #191919;
            @include mq(md-min) {
                position: absolute;
                top: -10px;
                left: 61px;
            }
            @include mq(xl) {
                @include font-size(41);
            }
            @include mq {
                @include fts(32,38);
                display: block;
                margin-bottom: 3px;
            }
            &::before {
                content: "0" counter(counterNumber);
            }
        }
        &-note {
            position: absolute;
            top: 5px;
            right: 0;
            background: #141414;
            color: white;
            line-height: 1;
            @include font-size(16);
            padding: 7px 14px 8px 11px;
            letter-spacing: .04em;
            @include mq {
                @include fts(12,16);
                top: 38px;
            }
            @include mq(sm) {
                top: 26px;
                padding: 4px 5px 4px 5px;
                letter-spacing: .01em;
            }
        }
        &-circle {
            position: absolute;
            left: -31px;
            top: 89px;
            width: 82px;
            height: 82px;
            border: 1px solid #707070;
            border-radius: 50%;
            @include flex(center, center);
            text-align: center;
            font-weight: 300;
            @include fts(14,18);
            letter-spacing: 0.25em;
            background: white;
            @include mq {
                width: 59px;
                height: 59px;
                left: -8px;
                top: 77px;
                padding: 0 0 3px;
                @include font-size(13);
            }
            small {
                @include font-size(12);
                @include mq {
                    display: block;
                }
            }
            .number {
                font-family: $font-en;
                font-weight: 500;
                @include font-size(16);
                @include mq(){
                    @include font-size(14);
                }
                &--block {
                    display: block;
                }
            }
        }
        .c-txt__01 {
            line-height: 1.5;
            letter-spacing: .075em;
            @include mq {
                @include fts(14,24);
                letter-spacing: .08em;
            }
        }
    }
}