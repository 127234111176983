@use "vars" as *;

.sec-contact-page{
	padding: 89px 0 160px;
	@include mq(){
		padding: 38px 0 57px;
	}
}

.contact-note{
	text-align: center;
	@include font-size(16);
	line-height: 1.4;
	font-weight: 300;
	letter-spacing: 1px;
	margin-bottom: 31px;
	@include mq(){
		@include fts(14,24);
		text-align: left;
		letter-spacing: 1.2px;
	}
	p{
		margin-bottom: 27px;
		@include mq(){
			margin-bottom: 24px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	a{
		color: #141414;
	}
}

.contact-block{
	width: 100%;
	max-width: 679px;
	margin: 0 auto;
	@include font-size(16);
	&__item{
		margin-bottom: 31px;
		@include mq(){
			margin-bottom: 23px;
		}
		&.w50{
			@include mq(md-min){
				max-width: 50%;
			}
		}
		dt{
			@include flex(0,center,wrap);
			margin-bottom: 8px;
			letter-spacing: 1px;
			.note{
				display: block;
				line-height: 1;
				@include font-size(12);
				background: #141414;
				color: white;
				font-weight: 500;
				margin: 0 0 0 9px;
				padding: 2px 5px 3px;
				position: relative;
				top: 2px;
				@include mq(){
					margin: 0 0 0 6px;
					top: 1px;
				}
			}
		}
		dd{
			font-weight: 300;
		}
	}
	&__note{
		display: block;
		@include font-size(14);
		font-weight: 300;
		color: #919191;
		padding: 8px 0 0;
		margin-bottom: 8px;
		@include mq(){
			padding: 6px 0 0;
			margin-bottom: 6px;
		}
	}
	&__code{
		@include flex(0,0,wrao);
		&-number{
			width: 312px;
			margin-right: 10px;
			@include mq(){
				width: 55%;
			}
		}
		&-sub{
			width: calc(45% - 10px);
			.code-sub{
				@include font-size(16);
				font-weight: 300;
				@include flex(center,center);
				width: 136px;
				height: 100%;
				border: 1px solid #919191;
				outline: none;
				cursor: pointer;
				@include smooth-transition;
				background: white;
				color: #141414;
				&:hover{
					background: #919191;
					opacity: 1;
					color: white;
				}
			}
		}
	}
	&__check{
		@include flex(center,center,wrap);
		@include font-size(14);
		font-weight: 300;
		letter-spacing: 2px;
		margin-bottom: 20px;
	}
	&__sub{
		@include flex(center,center,wrap);
		.c-btn__03{
			margin: 0 7px 15px;
			@include mq(){
				margin-bottom: 6px;
			}
		}
		&-note{
			@include fts(12,18);
			font-weight: 300;
			text-align: center;
			letter-spacing: 1.7px;
			color: #919191;
		}
	}
}